<template>
  <h1 ref="text" class="hidden">{{ this.currentMessage }}</h1>
</template>

<script>
export default {
  data() {
    return {
      currentMessage: "",
      messages: [],
      lastMessageTime: Date.now() - 2500,
    };
  },
  props: {
    message: String,
  },
  methods: {
    displayMessage() {
      this.$refs.text.classList.remove("hidden");
      this.lastMessageTime = Date.now();
      this.currentMessage = this.messages[0];
      this.messages.shift();

      setTimeout(() => {
        this.$refs.text.classList.add("hidden");
      }, 2000);
    },
    addToList() {
      if (this.message) this.messages.push(this.message);
    },
    manager() {
      if (this.messages.length && this.lastMessageTime - Date.now() < -2500) {
        this.displayMessage();
      }
    },
  },
  created() {
    setInterval(this.manager, 500);
  },
  watch: {
    message: {
      immediate: true,
      deep: true,
      handler() {
        this.addToList();
      },
    },
  },
};
</script>

<style scoped>
h1 {
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.75);
  max-width: 90%;
  padding: 0.25rem 1rem;
  text-align: center;

  color: rgb(255, 255, 255);
  font-size: 21px;
  font-weight: 300;

  transition: 0.1s ease;
  opacity: 1;
}
.hidden {
  transform: translateY(50px);
  opacity: 0;
}

@media only screen and (max-width: 500px) {
  h1 {
    font-size: 17px;
  }
}
</style>

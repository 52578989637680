<template>
  <button @click.stop="this.$emit('clicked')">
    <p :style="simple && 'display: none'">Do koszyka</p>
    <img src="/assets/svg/koszyk.svg" alt="Cart" />
  </button>
</template>

<script>
export default {
  props: {
    simple: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
button {
  height: 100%;
  width: max-content;
  padding: 4px 8px 4px 12px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;

  background-color: var(--main-color-1);
  box-shadow: 0 0 0 var(--main-color-1-light);
  border: none;
  border-radius: 6px;

  cursor: pointer;
  transition: 0.4s;
}
button:hover {
  background-color: var(--main-color-1-dimm);
  box-shadow: 0 0 20px var(--main-color-1-light);
}
button p {
  font-size: 15px;
  color: black;
  font-weight: 500;
  white-space: nowrap;
}
button img {
  height: calc(100% - 10px);
  aspect-ratio: 1/1;
}

@media only screen and (max-width: 500px) {
  button {
    padding: 4px 8px 4px 12px;
    gap: 10px;
    border-radius: 4px;
  }

  button p {
    font-size: 12px;
  }
}
</style>

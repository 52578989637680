<template>
  <div class="cookies">
    <div class="center vertAlign">
      <div class="info vertAlign">
        <img src="/assets/img/cookie.png" alt="Cookie" />
        <div class="text">
          <h6>Cenimy twoją prywatność.</h6>
          <p>MyAURA prosi o zgodę na przechowywanie plików cookie w twojej przeglądarce w celu poprawy jakości świadczonych usług.</p>
          <!-- <p>MyAURA prosi o zgodę na przechowywanie plików cookie w twojej przeglądarce w celu poprawy jakości świadczonych usług. Kliknięcie przycisku zgoda umożliwi ci m. in.:</p> -->
          <!-- <ul>
            <li>Zachowanie produktów w koszyku pomiędzy zakupami.</li>
            <li>Pozostanie zalogowanym między zakupami.</li>
          </ul> -->
          <p>Zgodę możesz wycofać w każdej chwili w menu "Moja MyAura". Po więcej informacji na temat ciasteczek prosimy udać się do <router-link to="/privacy">polityki prywatności</router-link>.</p>
        </div>
      </div>
      <div class="action">
        <button class="primary" @click="this.$emit('cookiesAccepted')">Zgoda</button>
        <button class="secondary" @click="this.$emit('cookiesDeclined')">Zamknij</button>
      </div>
    </div>
  </div>
</template>

<style scoped>
::-webkit-scrollbar {
  width: 0.25rem;
}

.vertAlign {
  display: flex;
  align-items: center;
}

.cookies {
  max-height: 50vh;
  overflow-y: scroll;
  width: 100%;
  padding: 10px 20px;
  box-shadow: 0 0 20px #01010129;
  background-color: white;
  border-top: 1px solid #dbdbdb;
}
.center {
  max-width: 1600px;
  margin: 0 auto;

  justify-content: space-between;
  gap: 30px;
}
.center .info {
  display: flex;
  gap: 15px;
}
.center .info h6 {
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 5px 0;
}
.center .info p {
  font-size: 13px;
  font-weight: 400;
  margin: 5px 0;
}
.center .info p:last-of-type {
  margin: 5px 0 0 0;
}
.center .info ul {
  padding-left: 24px;
  list-style-type: circle;
}
.center .info li {
  font-size: 13px;
  font-weight: 400;
}
.center img {
  width: 28px;
  height: 28px;
  margin-bottom: auto;
}

.action button {
  width: 100%;
  padding: 0.7rem 1.8rem;
  margin: 8px 0;
  display: block;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  border-radius: 2px;
  border: none;
}
.action .primary {
  background-color: rgb(70, 67, 255);
  color: white;
}
.action .secondary {
  background-color: #ededed;
  color: #3b3b3b;
}

@media only screen and (max-width: 800px) {
  .cookies {
    padding: 20px;
  }
  .center {
    flex-direction: column;
    gap: 20px;
  }
  .action {
    width: 100%;
    display: flex;
    gap: 10px;
  }
}
@media only screen and (max-width: 400px) {
  .center .info h6 {
    font-size: 16px;
  }
  .center .info p {
    font-size: 11px;
  }
  .center .info li {
    font-size: 11px;
  }
  .center img {
    width: 24px;
    height: 24px;
  }
  .action button {
    font-size: 14px;
  }
}
</style>

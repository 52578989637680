<template>
  <div class="fancyInput">
    <div class="inputArea">
      <p id="placeholder" ref="placeholder">{{ fieldName }}</p>
      <div class="inputArray" ref="inputElement">
        <div class="inputBox" v-for="(input, i) of inputs" :key="i">
          <label v-if="input !== ''">{{ input }}</label>
          <input v-if="input === ''" :type="onlyNumbers ? 'number' : 'text'" ref="inputField" @input="handleInput(i)" @focus="(e) => focusEnd(e.target)" @keydown.backspace="handleDelete(i)" @keydown.left="handlePrev(i)" @keydown.right="handleNext(i)" placeholder="_" />
        </div>
      </div>
    </div>
    <div class="errorArea" v-show="error.message" :style="`background-Color: ${(error.type === 'warning' && '#efa25f') || (error.type === 'error' && '#ef5f5f') || (error.type === 'success' && '#438d38')}`">
      <!-- prettier-ignore -->
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M248,64C146.39,64,64,146.39,64,248s82.39,184,184,184,184-82.39,184-184S349.61,64,248,64Z" style="fill:none;stroke:#FFF;stroke-miterlimit:10;stroke-width:32px"/><polyline points="220 220 252 220 252 336" style="fill:none;stroke:#FFF;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/><line x1="208" y1="340" x2="296" y2="340" style="fill:none;stroke:#FFF;stroke-linecap:round;stroke-miterlimit:10;stroke-width:32px"/><path style="fill:#FFF" d="M248,130a26,26,0,1,0,26,26A26,26,0,0,0,248,130Z"/></svg>
      <h6 id="error">{{ error.message }}</h6>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: "",
    };
  },
  props: {
    fieldName: String,
    inputs: Array,
    error: Object,
    default: String,
    onlyNumbers: Boolean,
  },
  methods: {
    handleInput(i) {
      const inputArrayIndex = this.translateIndex(i);
      const input = this.$refs[`inputField`][inputArrayIndex];

      if (input.value.length > 1) input.value = input.value[1];
      if (input.value.length === 1) {
        if (this.actualInputs.length - inputArrayIndex - 1 > 0) {
          this.focusEnd(this.$refs[`inputField`][inputArrayIndex + 1]);
        }

        this.data[inputArrayIndex] = input.value;
        this.$emit("userInput", [...this.data].join(""));
      }
    },
    handleNext(i) {
      const inputArrayIndex = this.translateIndex(i);
      if (this.actualInputs.length - inputArrayIndex - 1 > 0) {
        this.focusEnd(this.$refs[`inputField`][inputArrayIndex + 1]);
      } else {
        this.focusEnd(this.$refs[`inputField`][inputArrayIndex]);
      }
    },
    handlePrev(i) {
      const inputArrayIndex = this.translateIndex(i);
      if (inputArrayIndex > 0) {
        this.focusEnd(this.$refs[`inputField`][inputArrayIndex - 1]);
      } else {
        this.focusEnd(this.$refs[`inputField`][inputArrayIndex]);
      }
    },
    handleDelete(i) {
      const inputArrayIndex = this.translateIndex(i);
      const input = this.$refs[`inputField`][inputArrayIndex];
      if (input.value.length === 0) {
        if (inputArrayIndex > 0) {
          this.$refs[`inputField`][inputArrayIndex - 1].focus();
        }
      } else {
        input.value = "";
        this.data[inputArrayIndex] = input.value;
        this.$emit("userInput", [...this.data].join(""));
      }
    },
    translateIndex(i) {
      let labels = 0;
      for (let n = 0; n < i; n++) {
        if (this.inputs[n] !== "") labels++;
      }
      return i - labels;
    },
    focusEnd(e) {
      const value = e.value;
      e.focus();
      e.value = "";
      setTimeout(() => (e.value = value), 0);
    },
    changeColor(colorInput = "#b9b9b9", colorPlaceholder = colorInput) {
      const input = this.$refs.inputElement;
      const placeholder = this.$refs.placeholder;

      input.style.borderColor = colorInput;
      placeholder.style.color = colorPlaceholder;
    },

    handleInputChange() {
      this.$emit("userInput", this.data);

      const input = this.$refs.inputElement;
      if (input === document.activeElement) {
        this.changeColor("#97acdf");
      } else {
        this.changeColor("#b9b9b9", "#a4a4a4");
      }
      input.style.borderRadius = "2px";
    },

    handleInputEnter() {
      if (!this.error.message) this.changeColor("#97acdf");
    },

    handleInputLeave() {
      if (!this.error.message) this.changeColor("#b9b9b9", "#a4a4a4");
    },

    handleInputError() {
      if (this.error.type === "warning") {
        this.changeColor("#efa25f");
      } else if (this.error.type === "error") {
        this.changeColor("#ef5f5f");
      } else if (this.error.type === "success") {
        this.changeColor("#438d38");
      } else {
        this.changeColor("#b9b9b9", "#a4a4a4");
      }

      const input = this.$refs.inputElement;
      input.style.borderRadius = "2px 2px 0 0";
    },
  },
  mounted() {
    this.actualInputs = this.inputs.filter((input) => input === "");
    this.data = [...this.actualInputs];

    this.$refs.inputElement.addEventListener("click", (e) => {
      if (e.target.tagName === "INPUT") return;
      this.$refs.inputField[0].focus();
    });

    const inputElements = this.$refs.inputField;

    inputElements.forEach((e) =>
      e.addEventListener("focus", () => {
        this.handleInputEnter();
      })
    );

    inputElements.forEach((e) =>
      e.addEventListener("blur", () => {
        this.handleInputLeave();
      })
    );

    if (this.error.message) this.handleInputError();

    setTimeout(() => {
      if (this.default && this.default !== "undefined") {
        if (this.default.length !== this.actualInputs.length) return;

        this.data.forEach((e, i) => {
          this.data[i] = this.default[i];
          this.$refs.inputField[i].value = this.data[i];
        });
      }
    }, 200);
  },
  watch: {
    error: {
      deep: true,
      handler() {
        this.handleInputError();
      },
    },
  },
};
</script>

<style scoped>
.fancyInput {
  margin: 15px 0 0 0;
}
.fancyInput:last-of-type {
  margin-bottom: 0;
}
.inputArea {
  position: relative;
}
.fancyInput .inputArea p {
  position: absolute;
  top: 0;
  left: 0.3rem;

  width: auto;
  background-color: #fff;
  border-radius: 2px;

  user-select: none;
  pointer-events: none;
  line-height: 1rem;
  padding: 0.2rem;

  font-size: 0.8rem;
  font-weight: 400;
  color: #a4a4a4;

  transform: translateY(-50%);
  transition: 0.2s font-size;
}
.fancyInput .inputArray {
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  padding: 0 5px;
  border: 1px solid #b9b9b9;
  background-color: #fff;
  transition: 0.2s;
}
.fancyInput .inputArray label {
  display: block;
  width: 10px;

  user-select: none;
  -webkit-user-select: none;

  font-size: 1rem;
  font-weight: 500;
  color: #2d2d2d;
}
.fancyInput .inputBox input {
  background-color: transparent;
  border: none;
  outline: none;
  text-align: center;

  width: 12px;

  caret-color: transparent;
  -moz-appearance: textfield;

  font-size: 1rem;
  font-weight: 500;
  color: #2d2d2d;
}
.fancyInput .inputBox input::-webkit-outer-spin-button,
.fancyInput .inputBox input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.fancyInput .inputBox input::placeholder {
  font-size: 1rem;
  font-weight: 300;
  color: #787878;
  /* transition: 0.1s; */
}
.fancyInput .inputBox input:focus {
  background-color: #d5d5d5;
}
.fancyInput .inputBox input:focus::placeholder {
  color: transparent;
}
.fancyInput .errorArea {
  display: grid;
  justify-content: left;
  align-items: center;
  grid-template-columns: 1rem auto;
  gap: 0.3rem;

  padding: 0.15rem 0.3rem;
  transition: 0.2s;
  background-color: #ef5f5f;
  border-radius: 0 0 2px 2px;
  user-select: none;
  pointer-events: none;
}
.fancyInput .errorArea svg {
  width: 1rem;
  height: 1rem;
}
.fancyInput .errorArea h6 {
  font-size: 0.8rem;
  font-weight: 400;
  color: #ffffff;
}
</style>

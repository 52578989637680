<template>
  <div class="container">
    <div class="box">
      <h1>Dziękujemy za złożenie zamówienia!</h1>
      <p>Zmiany statusu zamówienia będą wysyłane na maila.</p>
      <button @click="$router.push('/')">Strona główna</button>
      <router-link to="/products">Wróć do sklepu</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderSuccessView",
  created() {
    if (this.$route.query.clearcart == "true") {
      this.$emit("clearCart");
    }
  },
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  min-height: 100vh;
}
.box {
  margin: 20px 10px 0 10px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #eee;
  height: min-content;
  width: 100%;
  max-width: 500px;
  box-shadow: 15px 15px 30px #00000014;
  text-align: center;
}
.box h1 {
  line-height: 125%;
}
.box p {
  margin: 10px 0;
}
.box button {
  margin: 10px 10px 0 10px;
  padding: 10px 20px;
  background-color: var(--action-color);
  border: none;
  cursor: pointer;
  color: white;
}
.box button:hover {
  background-color: var(--action-color-hover);
}
.box a {
  padding: 10px 20px;
  margin: 10px 10px 0 10px;
  color: #000;
  white-space: nowrap;
  display: inline-block;
}
.box a:hover {
  background-color: #eee;
}
.box a,
.box button {
  border-radius: 2px;
  text-decoration: none;
  font-size: 16px;
}
</style>

<template>
  <div class="container">
    <img class="imageProd" :src="data.product.preview" alt="Preview" />
    <div class="details">
      <router-link class="name" :to="data.product._id.startsWith('SET') ? `/set-creator?set=${data.product._id}` : `/products/${data.product._id}`">{{ data.product.name }}</router-link>
      <p :class="['price', data.product.discount > 0 && 'discounted']">
        <span class="default">{{ `${data.product.price}`.slice(0, -2) + "," + `${data.product.price}`.slice(-2) }}zł</span> <span class="dsc" v-show="data.product.discount > 0">{{ `→ ${data.product.price - data.product.discount}`.slice(0, -2) + "," + `${data.product.price - data.product.discount}`.slice(-2) }} ({{ data.quantity }}x)</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.container {
  display: grid;
  grid-template-columns: 60px auto;
  gap: 10px;
  padding: 0;
}
.container .imageProd {
  height: 60px;
  width: 100%;
  object-fit: contain;
}
.container .name {
  display: block;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #3a3a3a;
  text-decoration: none;
  margin-bottom: 2px;
}
.container .price {
  line-height: 1;
}
.container .price .default {
  font-size: 12px;
  font-weight: 400;
  color: #454545;
}
.container .discounted .default {
  font-size: 12px;
  font-weight: 400;
  color: #454545;
  text-decoration: line-through;
}
.container .discounted .dsc {
  font-size: 12px;
  font-weight: 400;
  color: #454545;
  text-decoration: none;
}
</style>

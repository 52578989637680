<template>
  <div class="app">
    <NavBar :token="this.token" :user="this.user" :offlineCart="this.offlineCart" class="fixed" @openCartPrompt="openCartPrompt" @openAccountPrompt="openAccountPrompt" />
    <ServerMessage class="fixed" style="left: 50%; bottom: 100px; transform: translateX(-50%); z-index: 999" :message="this.message" />
    <transition>
      <CookiesPrompt class="fixed" v-if="!cookieConsent && !hideCookieBanner" style="left: 0; bottom: 0; z-index: 1" @cookiesAccepted="this.cookiesConsentChange(true)" @cookiesDeclined="this.cookiesConsentChange(false)" />
    </transition>
    <transition><PromptCart class="fixed" @closePrompt="closeCartPrompt" v-show="this.promptCartVisible" :token="this.token" @editCart="editCart" :setTypes="this.setTypes" :cart="this.token ? this.user.cart : this.offlineCart" /> </transition>
    <!-- <transition>
      <PromptMail
        class="fixed"
        @closePrompt="closeMailPrompt"
        v-if="this.promptMailVisible"
      />
    </transition> -->
    <transition>
      <PromptAccount class="fixed" :token="this.token" :cookieConsent="this.cookieConsent" @closePrompt="closeAccountPrompt" @signup="signup" @login="openLoginPrompt" @logout="logout" @editAccount="editAccount" @revokeCookieConsent="revokeCookieConsent" v-if="this.promptAccountVisible" />
    </transition>
    <transition>
      <PromptLogin class="fixed" @closePrompt="closeLoginPrompt" @saveData="updateUserData" v-if="this.promptLoginVisible" />
    </transition>
    <div class="spacer"></div>
    <router-view @serverMessage="showMessage" @openMailPrompt="openMailPrompt" @logout="logout" @updateUser="syncUser" @openLoginPrompt="openLoginPrompt" @updateOrderData="updateOrderData" @addToCart="editCart" @clearCart="clearCart" @saveToken="updateUserData" :token="this.token" :user="this.user" :setTypes="this.setTypes" :cart="this.token ? this.user.cart : this.offlineCart" />
    <FooterComp />
  </div>
</template>

<script>
import NavBar from "./components/NavBar.vue";
import CookiesPrompt from "./components/CookiesPrompt.vue";
import FooterComp from "./components/FooterComp.vue";
import PromptCart from "./components/PromptCart.vue";
// import PromptMail from "./components/PromptMail.vue";
import PromptAccount from "./components/PromptAccount.vue";
import PromptLogin from "./components/PromptLogin.vue";
import ServerMessage from "./components/ServerMessage";

export default {
  data() {
    return {
      cookieConsent: false,
      hideCookieBanner: false,

      token: "",
      user: {
        cart: [],
      },
      offlineCart: [],

      message: "",

      promptCartVisible: false,
      promptMailVisible: false,
      promptAccountVisible: false,

      promptLoginVisible: false,

      setTypes: [],
    };
  },
  components: {
    NavBar,
    CookiesPrompt,
    FooterComp,
    PromptCart,
    // PromptMail,
    PromptAccount,
    PromptLogin,
    ServerMessage,
  },
  methods: {
    logout() {
      this.user = {};
      this.token = "";
      this.closeAccountPrompt();

      if (!this.cookieConsent) return;
      const cart = window.localStorage.getItem("offlineCart");
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("user");
      window.localStorage.setItem("offlineCart", cart);
    },
    showMessage(message) {
      this.message = message;
    },
    clearCart() {
      // server side when logged in
      this.offlineCart = [];
      this.saveCartStorage();
    },
    revokeCookieConsent() {
      this.cookiesConsentChange(false);
      this.hideCookieBanner = false;
    },
    cookiesConsentChange(status) {
      this.cookieConsent = status;
      this.hideCookieBanner = true;

      if (this.cookieConsent === true) {
        this.hideCookieBanner = window.localStorage.setItem("cookieConsent", true);
      } else {
        window.localStorage.clear();
      }
    },
    async editCart(productId, quantity = 0) {
      if (!this.token) {
        let added = false;

        this.offlineCart = this.offlineCart.filter((el) => {
          if (el.product != productId) {
            return true;
          } else {
            added = true;
            el.quantity += quantity;
            if (quantity === 0) el.quantity = 0;
            if (el.quantity > 0) {
              return true;
            }
          }
        });

        if (!added) this.offlineCart.unshift({ product: productId, quantity: quantity });

        this.saveCartStorage();
      } else {
        let method;

        if (quantity <= 0) {
          method = "DELETE";
          quantity = Math.abs(quantity);
        } else {
          method = "POST";
        }

        const req = await fetch("/api/carts", {
          method,
          headers: {
            Authorization: `Bearer ${this.token}`,
            "content-type": "application/json",
          },
          body: JSON.stringify({
            productId,
            quantity,
          }),
        });

        const res = await req.json();

        if (req.status === 200) {
          this.user.cart = res.data;
          this.message = res.message;
        } else if (req.status === 401) {
          this.message = res.message;
          this.logout();
        } else {
          console.log(res);
        }
      }

      if (navigator?.vibrate) navigator.vibrate(100);
      this.openCartPrompt();
    },
    updateUserData(token, user) {
      this.token = token;
      this.user = user;

      this.saveUserStorage();
    },
    async updateOrderData() {
      await this.syncUser();
      this.clarifyOrders();
    },
    async syncUser() {
      if (!this.token || this.token === undefined) return this.logout();

      const req = await fetch("/api/users", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      });

      const res = await req.json();

      if (req.status === 200) {
        this.user = res.data;
        this.saveUserStorage();
      } else if (req.status === 401) {
        if (this.token) {
          this.message = res.message;
          this.logout();
        }
      } else {
        this.message = res.message;
      }
    },
    countPriceAtPurchase(list) {
      let output = 0;
      list.forEach((el) => {
        output += el.quantity * el.priceAtPurchase;
      });
      return output;
    },
    generateString(list) {
      let output = "";
      list.forEach((el) => {
        output += `${el.quantity}x ${el.name}, `;
      });
      return output.slice(0, -2);
    },
    clarifyOrders() {
      this.user.orders.forEach((order) => {
        order.productString = this.generateString(order.products);
      });

      if (this.user.orders.length > 1 && !["zakończone", "anulowane"].includes(this.user.orders[0].status)) {
        [this.user.recentOrder, ...this.user.orders] = this.user.orders;
      } else if (this.user.orders.length === 1 && !["zakończone", "anulowane"].includes(this.user.orders[0].status)) {
        this.user.recentOrder = this.user.orders[0];
        this.user.orders = [];
      } else {
        this.user.recentOrder = [];
      }
    },

    saveUserStorage() {
      if (!this.cookieConsent) return;
      window.localStorage.setItem("token", this.token);
    },
    saveCartStorage() {
      if (!this.cookieConsent) return;
      window.localStorage.setItem("offlineCart", JSON.stringify(this.offlineCart));
    },
    getStorage() {
      const storageToken = window.localStorage.getItem("token");
      if (storageToken) {
        this.token = storageToken;
      }

      this.offlineCart = JSON.parse(window.localStorage.getItem("offlineCart")) || [];
    },

    enableScroll() {
      document.querySelector("body").classList.remove("no-scroll");
    },
    disableScroll() {
      document.querySelector("body").classList.add("no-scroll");
    },
    signup() {
      this.closeAccountPrompt();
      this.$router.push("/signup");
    },
    editAccount() {
      this.closeAccountPrompt();
      this.$router.push("/account");
    },
    closeAccountPrompt() {
      this.promptAccountVisible = false;
      this.enableScroll();
    },
    openAccountPrompt() {
      this.promptAccountVisible = true;
      this.disableScroll();
    },
    closeMailPrompt() {
      this.promptMailVisible = false;
      this.enableScroll();
    },
    openMailPrompt() {
      this.promptMailVisible = true;
      this.disableScroll();
    },
    closeCartPrompt() {
      this.promptCartVisible = false;
      this.enableScroll();
    },
    openCartPrompt() {
      this.promptCartVisible = true;
      this.disableScroll();
    },
    closeLoginPrompt() {
      this.promptLoginVisible = false;
      this.enableScroll();

      this.getStorage();
    },
    openLoginPrompt() {
      this.closeAccountPrompt();
      this.promptLoginVisible = true;
      this.disableScroll();
    },

    async getSets() {
      try {
        const res = await fetch("/api/orders/sets", {
          method: "GET",
          headers: {
            "content-type": "application/json",
          },
        });
        const data = await res.json();
        this.setTypes = data.data;
      } catch (err) {
        console.error(err);
      }
    },
  },
  created() {
    this.getStorage();
    if (this.token) this.syncUser();

    window.addEventListener("datalayerpush", (event) => {
      console.log(`Value pushed to dataLayer: ${JSON.stringify(event.detail, null, 2)}`);
    });

    // const cookieConsentGiven = window.localStorage.getItem("cookieConsent");
    // if (cookieConsentGiven === "true") this.cookieConsent = true;

    // We set cookie consent to true by default (should be ok for the cart storage)
    this.cookieConsent = true;

    // setTimeout(() => {
    //   if (!window.localStorage.getItem("signedUpForNewsletter")) {
    //     this.openMailPrompt();
    //   }
    // }, 15 * 1000);

    this.getSets();
  },
  watch: {
    message: {
      immediate: true,
      handler() {
        setTimeout(() => {
          this.message = "";
        }, 1);
      },
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --main-color-1-dimm: #e8bc5d;
  --main-color-1: #fcc959;
  --main-color-1-light: #ffd67f;

  --action-color: #4643ff;
  --action-color-hover: #3734f7;

  --font-color: #393939;

  --nav-height: 70px;
  --nav-height-mobile: 70px;
}

/* width */
::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgb(241, 241, 241);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(196, 196, 196);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(129, 129, 129);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  font-family: "Poppins", sans-serif;
  font-display: fallback;
}
body {
  background-color: rgb(244, 244, 244);
  font-family: "Poppins", sans-serif;
  font-display: fallback;

  -webkit-tap-highlight-color: transparent;
  /* user-select: none;
  -webkit-user-select: none; */
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: 100%;
}

.no-scroll {
  overflow-y: hidden;
}
.fixed {
  position: fixed;
}
.spacer {
  height: 70px;
}
.loading {
  min-height: 100vh;
}
.flexVert {
  display: flex;
  align-items: center;
}

.span-dot {
  display: inline-block;
  width: 3px;
  height: 3px;
  border-radius: 2px;
  background-color: #4a4a4a;
  margin: 0 9px 4px 9px;
}

.hover-span {
  position: relative;
  border-bottom: 2px dotted #a7a7a7;
  cursor: pointer;
}
.hover-span:hover {
  background-color: #00000013;
}
.hover-span::after {
  z-index: 999;
  position: absolute;
  top: calc(100% - 5px);
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  box-shadow: 0 0 10px #00000038;
  border-radius: 4px;
  border: 1px solid #e9e9e9;
  padding: 10px;
  min-width: 270px;
  max-width: 340px;
  font-weight: 400;
  font-size: 14px;

  opacity: 0;
  pointer-events: none;
  transition: 0.15s;
}
.hover-span:hover::after {
  opacity: 1;
  top: calc(100% + 5px);
}

@media only screen and (max-width: 1000px) {
  :root {
    --nav-height: 50px;
    --nav-height-mobile: 50px;
  }

  .spacer {
    height: 50px;
  }
}

.v-enter-active,
.v-leave-active {
  transition: 0.3s cubic-bezier(0.15, 1.01, 0.41, 0.96);
}

.v-enter-from,
.v-leave-to {
  transform: scale(1.33);
  opacity: 0;
}

.slideUp-enter-active,
.slideUp-leave-active {
  transition: 0.3s cubic-bezier(0.15, 1.01, 0.41, 0.96);
}

.slideUp-enter-from,
.slideUp-leave-to {
  transform: translateY(80px);
  opacity: 0;
}

@keyframes loading {
  from {
    background-color: #ededed;
    background: linear-gradient(100deg, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 60%) #ededed;
    background-size: 200% 100%;
    background-position-x: 180%;
    color: transparent;
  }
  to {
    background-color: #ededed;
    background: linear-gradient(100deg, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 60%) #ededed;
    background-size: 200% 100%;
    background-position-x: -20%;
    color: transparent;
  }
}
</style>

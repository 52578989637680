<template>
  <div class="page">
    <div class="centered section">
      <h1>O nas</h1>
      <p>Zapachy są dla Ciebie ważne? Wierzysz, że otaczanie się ulubionymi zapachami bezpośrednio wpływa na Twój nastrój, energetykę i aurę? Jesteś we właściwym miejscu!</p>
      <p>Kim jesteśmy?</p>
      <p>Agata i Magda. Jesteśmy dwoma przyjaciółkami. Poznałyśmy się podczas realizacji naszych tanecznych pasji. Szybko okazało się, że łączy nas o wiele więcej, między innymi zamiłowanie do pięknych zapachów.</p>
      <p>Pewnego wieczora, buszując wśród olejków eterycznych i rozprawiając o ich nutach zapachowych, zapragnęłyśmy w tą aromatyczną podróż zaprosić wszystkie osoby, dla których zapachy nie są obojętne. Tak powstał pomysł powołania do życia projektu MyAURA. Z naszymi produktami pragniemy dać każdej osobie możliwość otulić się ulubionymi zapachami w każdej chwili, w każdym miejscu, w każdych okolicznościach. <br />Nasze produkty inspirowane są czterema żywiołami. Każdy z nas jest mieszaniną żywiołów, które nas kreują, kształtują, identyfikują. Jesteśmy cudownymi istotami powołanymi do życia w konkretnym miejscu i czasie. Każdy z nas jest inny i ale każdy jest przepiękny. Taka też jest MyAURA: cztery zapachy, cztery żywioły, cztery historie.</p>
      <p>Zadbaj o swoją aurę z MyAURA.</p>

      <h1>O naszych produktach</h1>
      <p>Podczas, gdy teorii dotyczących mechanizmu detekcji i rozpoznania zapachów jest kilka i są one nadal intensywnie badane, jedno jest pewne - zapachy odgrywają wiele ważnych ról w naszym życiu, co zostało już opisane w niejednym opracowaniu naukowym. Zmysł powonienia może ostrzegać nas przed niebezpieczeństwem, jak również przywoływać wspomnienia z przeszłości.</p>
      <p>Ponadto udokumentowane jest działanie terapeutyczne zapachów, oraz wpływające na poprawę nastroju.</p>
      <p>Podczas badań przeprowadzonych z użyciem naszych mgiełek do ciała, aż 75 % - 95 % testujących potwierdziło, że produkty mają przyjemny zapach, a 70 % - 95 % potwierdziło, że zapach produktów pozytywnie wpływa na ich samopoczucie (oceny dotyczą czterech badanych produktów, oraz różnią się w zależności od produktu). Badania zostały przeprowadzone przez zewnętrzne laboratorium, a szczegółowe wyniki dostępne są w opisach produktów.</p>
      <p>Jeżeli dla Ciebie również zapachy są ważne i lubisz otaczać się przyjemną aurą, to jesteś we właściwym miejscu!</p>
    </div>
  </div>
</template>

<style scoped>
.page {
  min-height: calc(100vh - var(--nav-height-mobile));
  display: flex;
  align-items: center;
}
.centered {
  max-width: 960px;
  margin: 0 auto;
}
.section {
  padding: 20px;
}

h1 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}
p {
  font-size: 1rem;
  margin-bottom: 10px;
}
</style>

<template>
  <div class="screen">
    <h1>Przkierowuję...</h1>
    <p>{{ id }}</p>
  </div>
</template>

<script>
export default {
  name: "RedirectView",
  data() {
    return {
      id: null,
      redirects: {
        "00000": "/products/64f2257e2814c2a1b7ad46e5",
        "00001": "/products/64f225d82814c2a1b7ad4706",
        "00002": "/products/64f2253a2814c2a1b7ad46dc",
        "00003": "/products/64f2248e2814c2a1b7ad46c3",
      },
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.$router.push(this.redirects[this.id]);
  },
};
</script>

<style scoped>
.screen {
  min-height: 100vh;
  padding: 2rem;
}
</style>

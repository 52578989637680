<template>
  <div class="privacy" id="top">
    <div class="top">
      <div class="title">
        <h1>Prywatność</h1>
        <a id="download" href="/assets/doc/PolitykaPrywatnosciWSklepieInternetowym.pdf" download="PolitykaPrywatnosciWSklepieInternetowym.pdf">
          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 22H5V20H19V22ZM12 18L6 12L7.41 10.59L11 14.17V2H13V14.17L16.59 10.59L18 12L12 18Z" fill="#555555" />
          </svg>
        </a>
      </div>
    </div>
    <div class="content">
      <!-- <a href="#top">Powrót na szczyt</a> -->
    </div>
  </div>
</template>

<script>
export default {
  created() {
    document.title = `MyAURA - Prywatność`;
  },
};
</script>

<style scoped>
.privacy {
  padding: 20px 0;
  margin: 0 auto;
  max-width: 800px;
  min-height: calc(100vh - var(--nav-height));

  background-color: rgb(248, 248, 248);
  box-shadow: 10px 0 20px rgb(223, 223, 223);
}
.privacy a {
  display: block;
  text-align: center;
  color: gray;
  text-decoration: none;
}
.top {
  margin-bottom: -80px;
  padding: 30px;
  background-color: white;
}
.top h1 {
  font-size: 44px;
  font-weight: 800;
}
.top a {
  display: inline-block;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  color: rgb(74, 74, 255);
  margin-right: 1.5rem;
  word-wrap: normal;
}
.top a:hover {
  color: rgb(43, 43, 240);
  text-decoration: underline;
}
.content {
  padding: 30px;
}
.title {
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#download {
  font-size: 15px;
  color: rgb(145, 145, 145);
  margin-right: 0;
}
#download svg {
  margin: auto;
  height: 40px;
  display: block;
  color: rgb(145, 145, 145);
}
section {
  user-select: text;
  -webkit-user-select: text;
  word-wrap: break-word;
  padding-top: 90px;
  padding-bottom: 10px;
}
section h2 {
  font-size: 33px;
  font-weight: 700;
}
section h2 span {
  font-size: 36px;
}
section h3 {
  font-size: 24px;
  font-weight: 600;
  color: rgb(35, 35, 35);
  margin-top: 2rem;
}
section h6 {
  font-size: 17px;
  font-weight: 400;
  margin-bottom: 2rem;
  color: rgb(50, 50, 50);
}
section p {
  font-size: 17px;
  font-weight: 400;
  color: rgb(50, 50, 50);
  margin-bottom: 1rem;
}
section ol,
section ul {
  margin: 0.5rem 0;
  padding-left: 2rem;
  color: rgb(67, 67, 67);
}
span {
  font-weight: 700;
  color: rgb(21, 21, 21);
}

@media only screen and (max-width: 830px) {
  .privacy {
    width: calc(100% - 20px);
  }
  .top {
    padding: 20px;
  }
  .top h1 {
    font-size: 38px;
  }
  .top a {
    font-size: 18px;
    text-align: left;
    display: block;
  }
  .top a span {
    font-size: 22px;
  }
  .content {
    padding: 20px;
  }
  section h2 {
    font-size: 29px;
  }
  section h3 {
    font-size: 20px;
  }
  section h6 {
    font-size: 15px;
  }
  section p {
    font-size: 15px;
  }
  section ol,
  section ul {
    padding-left: 1.5rem;
  }
}
</style>

<template>
  <div class="background">
    <div class="center">
      <h1>Witaj, {{ this.$route.query.mail.split("@")[0] }}</h1>
      <p>Na Twoją prośbę wykluczamy Twój adres mailowy z listy adresatów newslettera.</p>
      <p>Nie będziesz już otrzymywał od nas regularnie wiadomości na ten adres email.</p>
      <h6>Dziękujemy za zainteresowanie,<br />MyAura</h6>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    document.title = `MyAURA`;
  },
  mounted() {
    console.log("Sending request");
    fetch(`/api/mails/newsletter?mail=${this.$route.query.mail}&code=${this.$route.query.code}`, {
      method: "DELETE",
    });
  },
};
</script>

<style scoped>
.background {
  width: 100%;
  min-height: calc(100vh - var(--nav-height-mobile));

  background-color: rgb(244, 244, 244);
}
.center {
  background-color: white;
  max-width: 500px;
  margin: 60px auto;

  padding: 30px;
}
.center h1 {
  font-size: 27px;
  font-weight: 600;
  margin-bottom: 1rem;
}
.center p {
  margin: 2rem 0;

  color: rgb(43, 43, 43);
  font-size: 18px;
  font-weight: 500;
}
.center h6 {
  margin-top: 1rem;

  color: rgb(43, 43, 43);
  font-size: 20px;
  font-weight: 600;
  text-align: right;
}

@media only screen and (max-width: 1000px) {
  .background {
    min-height: calc(100vh - var(--nav-height));
  }
}
@media only screen and (max-width: 520px) {
  .center {
    margin: 60px 10px;

    padding: 15px;
  }
}
</style>

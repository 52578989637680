<template>
  <footer>
    <svg class="shape" viewBox="0 0 1000 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1000 0C778.891 25.0015 587.5 30 500 30C422.115 30 221.167 25.0015 0 0C41.2088 0 223.858 0 500 0C776.142 0 923.077 0 1000 0Z" />
    </svg>
    <div class="grid">
      <div class="brand">
        <img src="/assets/svg/logo.svg" alt="Logo MyAURA" />
        <!-- <p>Niech zapach przemówi</p> -->
      </div>
      <div class="links">
        <h3>Linki</h3>
        <router-link to="/statute">Regulamin</router-link>
        <router-link to="/privacy">Prywatność</router-link>
      </div>
      <div class="social">
        <h3>Zaobserwuj nas</h3>
        <a href="https://www.instagram.com/myaura_scents/" target="_blank">
          <svg class="socialIcons" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M14.996 9.99787C12.2417 9.99787 9.99393 12.2456 9.99393 15C9.99393 17.7544 12.2417 20.0021 14.996 20.0021C17.7503 20.0021 19.998 17.7544 19.998 15C19.998 12.2456 17.7503 9.99787 14.996 9.99787ZM29.9983 15C29.9983 12.9286 30.0171 10.876 29.9008 8.80832C29.7844 6.40669 29.2366 4.27525 27.4804 2.51907C25.7205 0.759128 23.5929 0.215011 21.1913 0.098682C19.1199 -0.0176466 17.0673 0.00111617 14.9997 0.00111617C12.9284 0.00111617 10.8758 -0.0176466 8.80815 0.098682C6.40658 0.215011 4.27517 0.76288 2.51902 2.51907C0.759114 4.27901 0.215007 6.40669 0.0986802 8.80832C-0.0176462 10.8797 0.00111615 12.9324 0.00111615 15C0.00111615 17.0676 -0.0176462 19.124 0.0986802 21.1917C0.215007 23.5933 0.762867 25.7247 2.51902 27.4809C4.27893 29.2409 6.40658 29.785 8.80815 29.9013C10.8795 30.0176 12.9321 29.9989 14.9997 29.9989C17.0711 29.9989 19.1237 30.0176 21.1913 29.9013C23.5929 29.785 25.7243 29.2371 27.4804 27.4809C29.2403 25.721 29.7844 23.5933 29.9008 21.1917C30.0208 19.124 29.9983 17.0714 29.9983 15ZM14.996 22.6964C10.7369 22.6964 7.29966 19.2591 7.29966 15C7.29966 10.7409 10.7369 7.30355 14.996 7.30355C19.255 7.30355 22.6923 10.7409 22.6923 15C22.6923 19.2591 19.255 22.6964 14.996 22.6964ZM23.0075 8.7858C22.0131 8.7858 21.2101 7.98276 21.2101 6.98834C21.2101 5.99391 22.0131 5.19087 23.0075 5.19087C24.0019 5.19087 24.8049 5.99391 24.8049 6.98834C24.8052 7.22447 24.7589 7.45833 24.6687 7.67655C24.5785 7.89476 24.4461 8.09303 24.2791 8.26C24.1122 8.42697 23.9139 8.55936 23.6957 8.64958C23.4775 8.73981 23.2436 8.7861 23.0075 8.7858Z"
            /></svg
        ></a>
        <a href="https://www.facebook.com/search/top?q=my%20aura" target="_blank">
          <svg class="socialIcons" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 15.0838C0 22.5413 5.41625 28.7425 12.5 30V19.1663H8.75V15H12.5V11.6663C12.5 7.91625 14.9163 5.83375 18.3337 5.83375C19.4163 5.83375 20.5837 6 21.6663 6.16625V10H19.75C17.9163 10 17.5 10.9163 17.5 12.0837V15H21.5L20.8337 19.1663H17.5V30C24.5837 28.7425 30 22.5425 30 15.0838C30 6.7875 23.25 0 15 0C6.75 0 0 6.7875 0 15.0838Z" /></svg
        ></a>
      </div>
      <div class="contact">
        <h3>Skontaktuj się z nami</h3>
        <ul>
          <li>kontakt@myaura.pl</li>
          <li>+48 693 378 357</li>
        </ul>
      </div>
    </div>
    <!-- <p id="devCredit">Wykonawca: Maciej Szuter &copy; 2022</p> -->
  </footer>
</template>

<style scoped>
footer {
  background-color: #242424;
  position: relative;
  padding-top: calc(100vw * (30 / 1000));
}
.grid {
  max-width: 1700px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas: "brand social links contact";
  padding: 100px 50px 75px 50px;
}
.shape {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  aspect-ratio: 1000/30;
}
.shape path {
  fill: rgb(244, 244, 244);
}
.brand img {
  height: 100px;
}
.brand p {
  margin-top: 0.5rem;
  font-size: 18px;
  color: rgb(240, 240, 240);
}
h3 {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 0.25rem;
  color: white;
}
.links a {
  font-size: 18px;
  color: rgb(192, 192, 192);
  margin-top: 0.75rem;
  display: block;
  width: min-content;
  text-decoration: none;
}
.contact li {
  user-select: text;
  -webkit-user-select: text;
  pointer-events: auto;
  list-style: none;
  font-size: 18px;
  color: rgb(192, 192, 192);
  margin-top: 1rem;
}
.links a:hover {
  text-decoration: underline;
}
.socialIcons {
  margin: 1rem 1rem 0 0;
  display: inline-block;
  width: 30px;
  aspect-ratio: 1/1;
}
.socialIcons path {
  fill: rgb(118, 118, 118);
}
.brand {
  grid-area: brand;

  /* w/out text */
  display: flex;
  align-items: center;
}
.links {
  grid-area: links;
}
.social {
  grid-area: social;
}
.contact {
  grid-area: contact;
}

#devCredit {
  position: absolute;
  bottom: 2px;
  left: 0;
  width: 100%;

  font-size: 15px;
  font-weight: 400;
  text-align: center;
  color: rgb(19, 19, 19);
}

@media only screen and (max-width: 1200px) {
  .grid {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: "brand links contact" "social empty empty";
    gap: 10px;
    padding: 60px 20px;
  }
  .social h3 {
    display: none;
  }
}
@media only screen and (max-width: 850px) {
  .grid {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "brand links" "social contact";
    row-gap: 60px;
  }
  .social h3 {
    display: block;
  }
}
@media only screen and (max-width: 620px) {
  .shape {
    top: -1px;
  }
  .brand p {
    font-size: 15px;
  }
  h3 {
    font-size: 22px;
  }
  .socialIcons {
    width: 25px;
  }
  .links a {
    font-size: 15px;
  }
  .contact li {
    font-size: 15px;
  }
}
@media only screen and (max-width: 500px) {
  .grid {
    grid-template-columns: 1fr;
    grid-template-areas: "brand" "links" "social" "contact";
    row-gap: 60px;
    padding: 60px 20px;
  }
  .brand {
    /* text-align: center; */
    justify-content: center;
  }
  .brand p {
    font-size: 20px;
  }
  h3 {
    font-size: 24px;
  }
  .socialIcons {
    width: 26px;
  }
  .links a {
    font-size: 17px;
  }
  .contact li {
    font-size: 17px;
  }
}
@media only screen and (max-width: 330px) {
  .grid {
    padding: 60px 15px;
  }
  .brand p {
    font-size: 16px;
  }
  h3 {
    font-size: 22px;
  }
  .socialIcons {
    width: 22px;
  }
  .links a {
    font-size: 15px;
  }
  .contact li {
    font-size: 15px;
  }
}
</style>

<template>
  <div class="container">
    <div class="info">
      <p class="text" :style="!data.loaded && `animation: 1.5s loading linear infinite`">Do zapłaty</p>
      <p class="number" :style="!data.loaded && `animation: 1.5s loading linear infinite`">
        <span v-show="data.valueSubtracted">{{ data.loaded && `${data.sum}`.slice(0, -2) + "," + `${data.sum}`.slice(-2) + "zł" }} → </span>{{ data.loaded ? `${data.sum - data.valueSubtracted}`.slice(0, -2) + "," + `${data.sum - data.valueSubtracted}`.slice(-2) + "zł" : "Calculating" }}
      </p>
    </div>
    <button @click="$emit('scrollToButton')">Zobacz podsumowanie</button>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
  },
};
</script>

<style scoped>
.container {
  border: 1px solid #e5e5e5;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 15px 15px 45px 15px;
  transform: translateY(15px);
  background-color: #fff;
}
.container .info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 5px;
}
.container .text {
  font-size: 0.8rem;
  font-weight: 400;
  color: #5a5a5a;
}
.container .number {
  font-size: 1.25rem;
  font-weight: 700;
  color: #3e3e3e;

  display: flex;
  align-items: center;
  text-align: right;
}
.container .number span {
  font-size: 0.8rem;
  font-weight: 400;
  color: #5a5a5a;

  margin: 0 4px 0 0;
}
.container button {
  width: 100%;
  padding: 0.5rem 1.5rem;

  background-color: var(--action-color);
  border-radius: 2px;
  border: none;

  font-size: 1rem;
  font-weight: 600;
  color: #ffffff;
  cursor: pointer;
}
.container button:hover {
  background-color: var(--action-color-hover);
}
.container button svg {
  display: inline;
  width: 16px;
  aspect-ratio: 1/1;
}
.container button path {
  fill: white;
}

@media only screen and (max-width: 390px) {
  .container {
    padding: 15px 15px 60px 15px;
  }
}
</style>

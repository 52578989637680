<template>
  <div class="overlay">
    <div class="prompt">
      <img class="close" src="/assets/img/close.png" alt="Close" @click="this.$emit('closePrompt')" />
      <div class="content">
        <div class="scroll">
          <div class="top">
            <h1>Konto MyAura</h1>
            <div class="action" v-if="!this.token">
              <button class="outline" @click="this.$emit('login')">
                Mam konto
                <img src="/assets/svg/log-in.svg" alt="Zaloguj się" />
              </button>
              <button class="full" @click="this.$emit('signup')">
                Utwórz konto
                <img src="/assets/svg/add-account.svg" alt="Utwórz konto" />
              </button>
            </div>
            <div class="action" v-if="this.token">
              <button @click="this.$emit('logout')" class="outline">
                Wyloguj się
                <img src="/assets/svg/logout.svg" alt="Wyloguj się" />
              </button>
              <button @click="this.$emit('editAccount')" class="full">
                Edytuj konto
                <img src="/assets/svg/edit.svg" alt="Edytuj konto" />
              </button>
            </div>
          </div>
          <div class="bottom">
            <h2>Zalety posiadania konta:</h2>
            <div class="grid">
              <div class="element">
                <img src="/assets/svg/cloud-sync.svg" alt="Cloud Sync" />
                <h1>Synchronizacja danych</h1>
                <p>Dane takie jak zawartość koszyka będą zapisywane na koncie i dostępne na wszystkich urządzeniach.</p>
              </div>
              <div class="element">
                <img src="/assets/svg/list-outline.svg" alt="Lista" />
                <h1>Śledzenie zamówień</h1>
                <p>Na koncie można śledzić zamówienie i przeglądać wszystkie poprzednie.</p>
              </div>
              <div class="element">
                <img src="/assets/svg/review.svg" alt="Opinia" />
                <h1>Zostawianie opinii</h1>
                <p>Konto daje możliwość ocenienia produktu.</p>
              </div>
            </div>
            <div class="legal">
              <div class="links">
                <a href="/statute" target="_blank">Regulamin</a>
                <a href="/privacy" target="_blank">Prywatność</a>
              </div>
              <div v-if="this.cookieConsent" class="cookies">
                <button @click="this.$emit('revokeCookieConsent')">Wycofaj zgodę na użycie plików cookie</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    token: String,
    cookieConsent: Boolean,
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 0.25rem;
}

.overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(5px);
  z-index: 300;

  display: flex;
  justify-content: center;
  align-items: center;
}
.prompt {
  background-color: #f5f5f5;
  aspect-ratio: 8/5;
  width: 1200px;

  position: relative;
}
.close {
  position: absolute;
  top: 1rem;
  right: 1rem;

  aspect-ratio: 1/1;
  width: 40px;
  cursor: pointer;
}

.content {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.scroll {
  min-height: 100%;
  height: min-content;
  width: 100%;
}

.top {
  background-color: #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);

  padding: 40px 20px;
}
.top h1 {
  font-size: 50px;
  font-weight: 800;

  text-align: center;
}

.action {
  width: 100%;
  display: flex;
  justify-content: center;
}
.content button img {
  display: inline;
  width: 22px;
  aspect-ratio: 1/1;
  margin-left: 0.8rem;
}
.content button {
  padding: 0.6rem 1.2rem 0.6rem 1.4rem;
  border-radius: 2px;
  font-size: 20px;

  margin: 1.5rem 0.75rem 0.25rem 0.75rem;

  font-weight: 700;
  transition: 0.1s;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
}
.content .full {
  background-color: rgb(70, 67, 255);
  color: white;
  border: 2px solid rgb(55, 52, 247);
}
.content .full:hover {
  background-color: rgb(55, 52, 247);
}
.content .outline {
  background-color: white;
  color: rgb(55, 52, 247);
  border: 2px solid rgb(55, 52, 247);
}
.content .outline:hover {
  background-color: rgb(245, 245, 245);
}

.bottom {
  padding: 0 30px;

  width: 100%;
  height: 100%;
}
.bottom h2 {
  color: rgb(44, 44, 44);
  margin: 4rem 0 1.5rem 0;
  font-size: 30px;
}
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  grid-gap: 30px;
}
.element {
  padding: 20px 20px 70px 20px;
  background-color: white;

  height: 100%;
}
.element img {
  width: 36px;
  aspect-ratio: 1/1;
}
.element h1 {
  font-size: 22px;
  font-weight: 600;
  color: #3e3e3e;
  text-align: left;
  margin: 4px 0 8px 0;
}
.element p {
  font-size: 17px;
  font-weight: 400;
  color: #525252;
  text-align: left;
}
.legal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 0;
}
.legal button {
  margin: 0 1rem;
  padding: 0;
  font-size: 15px;
  color: #919191;
  font-weight: 400;
  border: none;
  background-color: transparent;
}
.bottom a {
  text-decoration: none;
  margin: 0 1rem;
  font-size: 15px;
  font-weight: 400;
  color: #919191;
}

@media only screen and (max-width: 1500px) {
  .prompt {
    width: 1000px;
  }
}

@media only screen and (max-width: 1200px) {
  .prompt {
    aspect-ratio: 5/4;
    width: 900px;
  }
}

@media only screen and (max-width: 1000px) {
  .prompt {
    aspect-ratio: 5/4;
    width: 800px;
  }

  .top {
    padding: 20px 20px;
  }
  .top h1 {
    font-size: 45px;
  }
  .content button {
    padding: 0.5rem 1rem 0.5rem 1.2rem;
    font-size: 17px;

    margin: 1rem 0.75rem 0.25rem 0.75rem;
  }
  .content button img {
    width: 20px;
    margin-left: 0.6rem;
  }
  .legal button {
    margin: 0 1rem;
    font-size: 15px;
  }
}
@media only screen and (max-width: 850px) {
  .prompt {
    aspect-ratio: unset;
    width: calc(100% - 60px);
  }
  .content {
    max-height: 70vh;
  }
  .grid {
    grid-template-columns: 1fr 1fr;
  }
  .element {
    padding: 20px;
  }
  .element:nth-child(2) {
    grid-row: 2/3;
    grid-column: 1/3;
  }
  .legal {
    flex-direction: column;
  }
  .legal button {
    margin: 20px 0 0 0;
  }
}
@media only screen and (max-width: 700px) {
  .content {
    max-height: 70vh;
  }
  .grid {
    grid-template-columns: 1fr;
  }
  .element:nth-child(2) {
    grid-row: auto;
    grid-column: auto;
  }
  .bottom h2 {
    text-align: center;
  }
  .legal {
    margin: 30px 0;
  }
}
@media only screen and (max-width: 600px) {
  .prompt {
    width: calc(100% - 40px);
  }
  .close {
    top: 0.75rem;
    right: 0.75rem;
    width: 30px;
  }
  .top h1 {
    font-size: 38px;
  }
  .content button {
    padding: 0.4rem 0.8rem 0.4rem 1rem;
    font-size: 16px;

    margin: 0.75rem 0.5rem 0.25rem 0.5rem;
  }
  .content button img {
    width: 20px;
    margin-left: 0.6rem;
  }
  .bottom h2 {
    margin: 3.5rem 0 1.5rem 0;
    font-size: 27px;
  }
  .grid {
    grid-gap: 1.25rem;
  }
  .element h1 {
    font-size: 20px;
  }
  .element p {
    font-size: 15px;
  }
  .legal button {
    font-size: 15px;
  }
}
@media only screen and (max-width: 500px) {
  .prompt {
    width: calc(100% - 30px);
  }
  .top {
    padding: 2rem 1rem 1rem 1rem;
  }
  .action {
    flex-direction: column;
  }
  .top h1 {
    margin-bottom: 0.75rem;
  }
  .content button {
    margin: 0.5rem;
  }
  .bottom {
    padding: 0 1rem;
  }
  .grid {
    grid-gap: 1rem;
  }
  .element {
    padding: 1rem;
  }
  .top h1 {
    font-size: 33px;
  }
}
@media only screen and (max-width: 450px) {
  .prompt {
    width: calc(100% - 20px);
  }
  .bottom h2 {
    font-size: 24px;
  }
  .element h1 {
    font-size: 18px;
  }
  .element p {
    font-size: 14px;
  }
}
</style>

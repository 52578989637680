<template>
  <div class="overlay">
    <div class="prompt">
      <img class="close" src="/assets/img/close.png" alt="Close" @click="this.$emit('closePrompt')" />
      <div class="content">
        <div class="scroll">
          <div class="top">
            <!-- <img src="/assets/svg/aura-cart.svg" alt="Koszyk" /> -->
            <div class="text">
              <h1>Twój koszyk</h1>
              <h2>{{ (this.list.length === 0 && "0 produktów") || (this.list.length === 1 && "1 produkt") || (this.list.length > 1 && 5 > this.list.length && `${this.list.length} produkty`) || `${this.list.length} produktów` }}<span class="span-dot"></span><span class="hover-span hover-rabat">Kody Rabatowe</span></h2>
            </div>
          </div>
          <div class="products" v-if="this.cart.length">
            <div class="desc">
              <p></p>
              <p>PRODUKT</p>
              <p>ILOŚĆ</p>
            </div>
            <div class="product" v-for="(item, i) of this.list" :key="i">
              <img :src="item.product.preview" alt="Preview" />
              <div class="center">
                <h1>
                  <router-link @click="this.$emit('closePrompt')" :to="item.product._id?.startsWith('SET') ? `/set-creator?set=${item.product._id}` : `/products/${item.product._id}`">{{ item.product.name }}</router-link>
                </h1>
                <h2>{{ `${item.product.price}`.slice(0, -2) + "," + `${item.product.price}`.slice(-2) }}zł</h2>
              </div>
              <div class="edit">
                <div class="options">
                  <div @click="this.editCart(item.product._id, 1)">
                    <h4>&#43;</h4>
                  </div>
                  <h3>{{ item.quantity }}x</h3>
                  <div @click="this.editCart(item.product._id, -1)">
                    <h4>&#45;</h4>
                  </div>
                </div>
              </div>
            </div>
            <div class="sum">
              <h1>Suma: {{ this.list.length ? `${sum}`.slice(0, -2) + "," + `${sum}`.slice(-2) : "... " }}zł</h1>
              <div class="toCheckout flexVert">
                <button @click="checkout">Do kasy <img src="/assets/svg/checkout-cart.svg" alt="Checkout" /></button>
                <p>Płatności obsługiwane przez: &nbsp;<img src="/assets/img/Przelewy24_logo.png" alt="Przelewy24" />&nbsp;&nbsp;<img src="/assets/img/Paypo_logo_black.png" alt="PayPo" /></p>
              </div>
            </div>
          </div>
          <div class="noProducts" v-if="!this.cart.length"><h1>Twój koszyk jest pusty</h1></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sum: 0,
      list: [],
    };
  },
  props: {
    setTypes: Array,
    token: String,
    cart: Array,
  },
  methods: {
    async prepareList() {
      if (this.token) {
        this.sum = 0;
        this.list = this.cart;

        this.cart.forEach((el) => {
          this.sum += el.product.price * el.quantity;
        });
        return;
      }
      const list = this.cart.map((el) => (el.product.startsWith("SET") ? "" : el.product));
      const req = await fetch("/api/products/many", {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ ids: list }),
      });
      const res = await req.json();
      const orderIds = res.data.map((el) => el._id);
      if (req.status === 200) {
        this.sum = 0;
        this.list = this.cart.map((el) => {
          if (el.product.startsWith("SET")) {
            const [type, name] = el.product.split(":");
            const set = this.setTypes.find((t) => t.name === name);
            this.sum += el.quantity * set?.price;
            return {
              product: {
                name,
                type,
                _id: el.product,
                preview: set.preview,
                price: set.price,
              },
              quantity: el.quantity,
            };
          } else {
            this.sum += res.data[orderIds.indexOf(el.product)].price * el.quantity;
            return {
              product: res.data[orderIds.indexOf(el.product)],
              quantity: el.quantity,
            };
          }
        });
      }
    },
    editCart(product, quantity) {
      this.$emit("editCart", product, quantity);
    },
    checkout() {
      this.$router.push("/checkout");
      this.$emit("closePrompt");
    },
  },
  watch: {
    cart: {
      immediate: true,
      deep: true,
      handler() {
        if (this.cart.length) {
          this.prepareList();
        }
      },
    },
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 0.25rem;
}

.hover-rabat::after {
  content: "Prosimy o wpisanie kodu rabatowego do odpowiedniego pola po przejściu do kasy." !important;
}

.overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(5px);
  z-index: 300;

  display: flex;
  justify-content: center;
  align-items: center;

  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}
.prompt {
  background-color: #f5f5f5;
  aspect-ratio: 10/6;
  max-height: 85vh;
  width: 1000px;

  position: relative;
}
.close {
  position: absolute;
  top: 20px;
  right: 20px;

  aspect-ratio: 1/1;
  width: 40px;
  cursor: pointer;
}

.content {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.scroll {
  min-height: 100%;
  height: min-content;
  width: 100%;
}

.top {
  margin: 0 1rem 0 1rem;
  padding: 1rem 0 1rem 0;

  display: flex;
  align-items: center;
  gap: 1rem;

  border-bottom: 1px dotted rgb(191, 191, 191);
}
.top img {
  width: 40px;
  aspect-ratio: 1/1;
}
.top h1 {
  font-size: 30px;
  font-weight: 700;
  color: #161616;
}
.top h2 {
  font-size: 15px;
  font-weight: 500;
  color: #373737;
}

button img {
  display: inline;
  width: 22px;
  aspect-ratio: 1/1;
  margin-left: 0.8rem;
}
button {
  margin: 10px 0 0 0;
  padding: 0.6rem 1.2rem 0.6rem 1.4rem;
  border-radius: 2px;
  font-size: 20px;

  font-weight: 700;
  transition: 0.1s;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
  background-color: rgb(70, 67, 255);
  color: white;
}
button:hover {
  background-color: rgb(55, 52, 247);
}

.products {
  margin-top: 20px;
  padding-bottom: 145px;
}
.product {
  margin: 0 10px;
  padding: 10px;
  /* background-color: white; */
  border-radius: 4px;
}
.desc {
  margin: 0 20px;
  text-align: left;
  color: #484848;
  font-weight: 400;
  font-size: 13px;
}
.product,
.desc {
  display: grid;
  grid-template-columns: 60px auto 125px;
  gap: 20px;
  align-items: center;
}
.product img {
  max-height: 60px;
  max-width: 60px;
  justify-self: center;
}
.product h1 {
  font-size: 18px;
  font-weight: 500;
  color: #333333;

  display: block;
  line-height: 20px;
}
.product a {
  text-decoration: none;
  color: #333333;
}
.product a:hover {
  text-decoration: underline;
}

.product h2 {
  font-size: 15px;
  font-weight: 500;
  color: #373737;
}
.product h3 {
  white-space: nowrap;
  font-size: 18px;
  font-weight: 500;
  color: #4b4b4b;
}

.edit {
  user-select: none;
  -webkit-user-select: none;
}
.options {
  display: flex;
  gap: 4px;
}
.options div {
  width: 36px;
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  text-align: center;
}
.options div:hover {
  background-color: #e8e8e8;
}
.options h4 {
  padding: 5px;
  font-weight: 600;
  font-size: 18px;
}
.options h3 {
  background-color: #ededed;
  padding: 5px;
  text-align: center;
  font-size: 18px;
  width: 45px;
  border: 1px solid rgb(187, 187, 187);
  border-radius: 3px;
}

.sum {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  background-color: white;
  padding: 20px;
  box-shadow: #0000000d 0 -10px 25px 10px;
}
.sum h1 {
  color: #161616;
  font-weight: 700;
  font-size: 30px;
}
.sum .toCheckout {
  gap: 16px;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 10px;
}
.sum .toCheckout button {
  margin: 0;
}
.sum .toCheckout p {
  font-size: 12px;
  color: #6e6e6e;
  display: flex;
  gap: 8px;
  align-items: center;
}
.sum .toCheckout img {
  height: 30px;
}

.noProducts h1 {
  margin: 40px 20px;
  text-align: center;
  color: #6e6e6e;
  font-size: 24px;
  font-weight: 400;
}

@media only screen and (max-width: 1200px) {
  .prompt {
    width: 900px;
  }
}

@media only screen and (max-width: 1000px) {
  .prompt {
    aspect-ratio: 5/4;
    width: 800px;
  }
}
@media only screen and (max-width: 850px) {
  .prompt {
    aspect-ratio: unset;
    width: calc(100% - 60px);
    height: calc(100vh - 200px);
  }
  .scroll {
    max-height: 70vh;
  }

  .product {
    margin: 0;
    padding: 1rem;

    display: grid;
    grid-template-columns: 50px auto 100px;
    gap: 1rem;
  }
  .desc {
    margin: 0 1rem;
    grid-template-columns: 50px auto 100px;
    gap: 1rem;
  }
  .product img {
    max-height: 50px;
    max-width: 50px;
  }

  .options div {
    width: 30px;
  }
  .options h3 {
    width: 40px;
  }
}

@media only screen and (max-width: 600px) {
  .prompt {
    width: calc(100% - 40px);
  }
  .close {
    width: 30px;
  }

  .sum .toCheckout {
    flex-direction: column;
  }
  .sum .toCheckout button {
    width: 100%;
  }
  .sum .toCheckout p {
    margin-top: 5px;
  }
}
@media only screen and (max-width: 500px) {
  .prompt {
    width: calc(100% - 30px);
  }

  .top {
    margin: 0 0.75rem 0 0.75rem;
    padding: 0.75rem 0 0.75rem 0;
  }

  .product,
  .desc {
    grid-template-columns: 50px auto 80px;
    gap: 0.5rem;
  }
  .product {
    padding: 0.5rem;
  }
  .desc {
    margin: 0 0.5rem;
  }

  .options h3 {
    font-size: 15px;
  }
  .options h4 {
    font-size: 15px;
  }

  .product h1 {
    font-size: 15px;
  }
  .product h2 {
    font-size: 13px;
  }
}
@media only screen and (max-width: 450px) {
  .prompt {
    width: calc(100% - 20px);
  }
  .element h1 {
    font-size: 18px;
  }
}
</style>

<template>
  <div class="overlay">
    <div class="prompt">
      <img class="close" src="/assets/img/close.png" alt="Close" @click="this.$emit('closePrompt')" />
      <div class="content">
        <div class="scroll">
          <h1>Zaloguj się</h1>
          <div class="form">
            <label class="textLabel">Email</label>
            <input class="textInput" type="email" :style="this.ok ? 'border-color:  #424242' : 'border-color: red'" @change="this.resetError" v-model="this.email" />
            <label class="textLabel">Hasło</label>
            <input class="textInput" type="password" :style="this.ok ? 'border-color:  #424242' : 'border-color: red'" @change="this.resetError" v-model="this.pwd" />
            <p :style="this.ok ? 'color: green' : 'color: red'">
              {{ error || "&nbsp;" }}
            </p>
            <button @click="this.login">Gotowe<img src="/assets/svg/arrow-right.svg" alt="Arrow Right" /></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      pwd: "",

      error: "",
      ok: true,
    };
  },
  methods: {
    resetError() {
      this.ok = true;
      this.error = "";
    },
    async login() {
      if (!this.email) {
        this.ok = false;
        this.error = "Proszę o wpisanie maila";
        return;
      }
      if (!this.pwd) {
        this.ok = false;
        this.error = "Proszę o wpisanie hasła";
        return;
      }

      this.ok = true;
      this.error = "Wysyłanie prośby o zalogowanie";

      const req = await fetch("/api/login", {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          email: this.email,
          password: this.pwd,
        }),
      });

      const res = await req.json();

      if (req.status === 200) {
        this.ok = true;
        this.error = res.message;

        setTimeout(() => {
          this.$emit("closePrompt");
          this.$emit("saveData", res.token, res.data);
        }, 1000);
      } else {
        this.ok = false;
        this.error = res.message;
        return;
      }
    },
  },
  created() {
    window.addEventListener("keypress", (e) => {
      if (e.key === "Enter") {
        this.login();
      }
    });
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 0.25rem;
}

.overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(5px);
  z-index: 300;

  display: flex;
  justify-content: center;
  align-items: center;
}
.prompt {
  background-color: white;
  width: 600px;
  max-height: 70vh;

  position: relative;
}
.close {
  position: absolute;
  top: 1rem;
  right: 1rem;

  aspect-ratio: 1/1;
  width: 50px;
  cursor: pointer;
}
.content {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.scroll {
  height: 100%;
  width: 100%;
  background-color: #f2f2f2;
}
.content h1 {
  padding: 1rem;
  margin-bottom: 1rem;
  font-size: 40px;
  line-height: 50px;
  font-weight: 700;
  background-color: #e8e8e8;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
.form {
  padding: 1rem 1rem 0 1rem;
}
.content h2 {
  color: rgb(63, 63, 63);
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 1rem;
}
.textLabel {
  display: block;
  font-size: 22px;
  font-weight: 500;
  margin: 6px 0;
}
.textInput {
  width: 100%;
  display: block;
  margin-bottom: 1rem;

  padding: 10px 12px;
  font-size: 19px;
  color: #4d4d4d;

  font-size: 17px;
  background-color: white;
  border: 1px solid #424242;
  border-radius: 0;
}
.content p {
  font-size: 15px;
}
.content button {
  padding: 0.8rem 1rem 0.8rem 1.2rem;
  border-radius: 2px;
  font-size: 16px;

  margin: 1rem 0;

  background-color: rgb(70, 67, 255);
  font-weight: 700;
  color: white;
  border: none;
  transition: 0.1s;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
}
.content button img {
  display: inline;
  width: 18px;
  aspect-ratio: 1/1;
  margin-left: 0.8rem;
}
.content button:hover {
  background-color: rgb(55, 52, 247);
}

@media only screen and (max-width: 1000px) {
  .prompt {
    width: 600px;
  }
  .close {
    width: 40px;
  }
  .content h1 {
    font-size: 30px;
    line-height: 38px;
  }
}
@media only screen and (max-width: 630px) {
  .prompt {
    width: calc(100% - 30px);
  }
}

@media only screen and (max-width: 450px) {
  .prompt {
    width: calc(100% - 20px);
  }
}
</style>

<template>
  <div class="product">
    <div class="loading" v-if="loading"></div>
    <transition name="slideUp">
      <div class="existant" v-if="exists && !loading">
        <header>
          <div class="top">
            <img :src="`/assets/svg/${product.element}-icon.svg`" :alt="`${product.element} icon`" />
            <div>
              <h1>{{ product.name }}</h1>
              <h2>{{ product.description }}</h2>
            </div>
          </div>
          <div class="center">
            <div class="slideshow">
              <div class="mainImgCont">
                <img :src="product.images[0]" :alt="`${this.product.name} image`" ref="mainImage" />
              </div>
              <div class="previews">
                <div class="preview" v-for="img of product.images" :key="img" @click="$refs.mainImage.src = img">
                  <img :src="img" :alt="`${product.name} image`" />
                </div>
              </div>
            </div>
            <div class="info">
              <h3>
                Informacje o produkcie
                <!-- prettier-ignore -->
                <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M248,64C146.39,64,64,146.39,64,248s82.39,184,184,184,184-82.39,184-184S349.61,64,248,64Z" style="fill:none;stroke-miterlimit:10"/><polyline points="220 220 252 220 252 336" style="fill:none;stroke-linecap:round;stroke-linejoin:round"/><line x1="208" y1="340" x2="296" y2="340" style="fill:none;stroke-linecap:round;stroke-miterlimit:10"/><path d="M248,130a26,26,0,1,0,26,26A26,26,0,0,0,248,130Z"/></svg> -->
              </h3>
              <ul>
                <li>
                  <div class="left">
                    <!-- prettier-ignore -->
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><polyline points="304 96 416 96 416 208" style="fill:none;stroke-linecap:round;stroke-linejoin:round"/><line x1="405.77" y1="106.2" x2="111.98" y2="400.02" style="fill:none;stroke-linecap:round;stroke-linejoin:round"/><polyline points="208 416 96 416 96 304" style="fill:none;stroke-linecap:round;stroke-linejoin:round"/></svg>
                    <p>Pojemność</p>
                  </div>
                  <span>{{ product.volume }}</span>
                </li>
                <li>
                  <div class="left">
                    <!-- prettier-ignore -->
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M448,341.37V170.61A32,32,0,0,0,432.11,143l-152-88.46a47.94,47.94,0,0,0-48.24,0L79.89,143A32,32,0,0,0,64,170.61V341.37A32,32,0,0,0,79.89,369l152,88.46a48,48,0,0,0,48.24,0l152-88.46A32,32,0,0,0,448,341.37Z" style="fill:none;stroke-linecap:round;stroke-linejoin:round"/><polyline points="69 153.99 256 263.99 443 153.99" style="fill:none;stroke-linecap:round;stroke-linejoin:round"/><line x1="256" y1="463.99" x2="256" y2="263.99" style="fill:none;stroke-linecap:round;stroke-linejoin:round"/></svg>
                    <p>Dostawa</p>
                  </div>
                  <span>1-3 dni robocze</span>
                </li>
                <li>
                  <div class="left">
                    <!-- prettier-ignore -->
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><rect x="64" y="320" width="48" height="160" rx="8" ry="8" style="fill:none;stroke-linecap:round;stroke-linejoin:round"/><rect x="288" y="224" width="48" height="256" rx="8" ry="8" style="fill:none;stroke-linecap:round;stroke-linejoin:round"/><rect x="400" y="112" width="48" height="368" rx="8" ry="8" style="fill:none;stroke-linecap:round;stroke-linejoin:round"/><rect x="176" y="32" width="48" height="448" rx="8" ry="8" style="fill:none;stroke-linecap:round;stroke-linejoin:round"/></svg>
                    <p>Dostępność</p>
                  </div>
                  <span>{{ product.avalibility }}</span>
                </li>
              </ul>
              <div class="shop">
                <h2>
                  {{ `${product.price}`.slice(0, -2) + "," + `${product.price}`.slice(-2) }}
                  zł
                </h2>
              </div>

              <div class="actions">
                <AddToCartButton @clicked="addToCart()" />
                <BuyNowButton @clicked="addToCart() || $router.push('/checkout')" />
              </div>

              <p v-if="product.contents">{{ product.contents }}</p>

              <p class="flexBottom" v-if="product.batchExpirationDate"><br />Najlepiej zużyć przed końcem: {{ product.batchExpirationDate }}</p>
              <p><br />Najniższa cena z ostatnich 30 dni: {{ product.monthlyLowestPrice ? `${product.monthlyLowestPrice}`.slice(0, -2) + "," + `${product.monthlyLowestPrice}`.slice(-2) + "zł" : "0zł" }}</p>
            </div>
          </div>
        </header>

        <section class="section sectionBriefInfo" id="section-1">
          <div class="container1400">
            <div class="howToUse" v-if="this.product.type === 'mgiełka'">
              <h1>Jak stosować mgiełkę MyAURA?</h1>
              <ul>
                <li>
                  <h2>01</h2>
                  <p>Rozpyl mgiełkę z odległości min 15 cm nad sobą i wejdź w powstały obłok.</p>
                </li>
                <li>
                  <h2>02</h2>
                  <p>Zalecamy stosowanie mgiełki na całe ciało, powtarzając aplikację, nawet kilkukrotnie w ciągu dnia.</p>
                </li>
                <li>
                  <h2>03</h2>
                  <p>Mgiełką można aplikować również w ubraniu - nie pozostawia ona plam na odzieży.</p>
                </li>
              </ul>
            </div>
          </div>
          <div class="howToUse" v-if="this.product.type === 'świeca'">
            <div class="container1400">
              <h1>Użytkowanie Świec Sojowych MyAURA</h1>
              <ul>
                <li class="span3">
                  <h2>01</h2>
                  <p>Użytkowanie świec sojowych MyAURA wymaga przestrzegania następujących zasad: aby świeca paliła się równomiernie, należy pozwolić całej powierzchni wosku stopić się - dzięki temu można uniknąć tzw tunelowania. To zazwyczaj zajmuje godzinę – dwie. Następnie dobrze jest świecy dać ostygnąć, a przed każdym kolejnym paleniem należy skracać knot do wysokości ok 0,5 cm - co pozwoli świecy palić się łagodnym płomieniem, oraz pozwoli uniknąć łamania się knota. Aby zapewnić trwały zapach i inne parametry użytkowe optymalny czas sesji palenia świecy sojowej to ok 2 - 3 godziny.</p>
                </li>
                <li class="span3">
                  <h2>02</h2>
                  <p>Świeca powinna stać z dala od źródeł ciepła (w tym innych świec - co najmniej 10 cm od siebie), z dala od działania słońca, z dala od przedmiotów łatwopalnych, nie w przeciągu, na podstawce zapobiegającej uszkodzeniu powierzchni, w miejscu niedostępnym dla dzieci i zwierząt.</p>
                </li>
                <li class="span3">
                  <h2>03</h2>
                  <p>Nie pozostawiaj palącej świecy bez nadzoru. Użytkując świece należy zachować szczególną ostrożność.</p>
                  <p></p>
                </li>
              </ul>
            </div>
          </div>
          <div class="benefitInfo" v-if="this.product.type === 'mgiełka'">
            <h1>Korzyści ze stosowania mgiełki MyAURA:</h1>
            <ul>
              <li class="benefit0">
                <img src="/assets/svg/checkmark-outline.svg" alt="Checkmark" />
                <p>Odświeżenie i uczucie komfortu o każdej porze dnia i nocy przez cały rok (np. w pracy, w podróży, w upalny dzień, przed i po treningu).</p>
              </li>
              <li class="benefit1">
                <img src="/assets/svg/checkmark-outline.svg" alt="Checkmark" />
                <p>Nawilżenie i pielęgnacja skóry po kąpieli.</p>
              </li>
              <li class="benefit2">
                <img src="/assets/svg/checkmark-outline.svg" alt="Checkmark" />
                <p>Zaperfumowanie i pielęgnacja włosów. Włosy pięknie rozczesują się i oddają zapachy.</p>
              </li>
              <li class="benefit3">
                <img src="/assets/svg/checkmark-outline.svg" alt="Checkmark" />
                <p>Poprawa nastroju dzięki poczuciu własnej aury, jej niezwykłego zapachu i energetyki.</p>
              </li>
            </ul>
          </div>
        </section>

        <!-- ======================================================================================== -->
        <!-- ======================================= OLD PART ======================================= -->
        <!-- ======================================================================================== -->

        <div class="phrase">
          <h1>
            {{ this.product.header }}
          </h1>
        </div>
        <section v-if="product.consumerTests?.length" class="section sectionConsumerTests" id="section-2">
          <div class="container1400">
            <div>
              <h1>Badania Konsumenckie</h1>
              <h3>Parametry użytkowe produktu zostały pozytywnie ocenione przez testujących*</h3>
            </div>
            <div class="testFade">
              <div class="fadeL"></div>
              <div class="tests">
                <div class="test" v-for="(test, i) of this.product.consumerTests" :key="i">
                  <h1>{{ test[0] }}<span>%</span></h1>
                  <p>
                    {{ test[1] }}
                  </p>
                </div>
              </div>
              <div class="fadeR"></div>
            </div>
            <p>* Badania zostały przeprowadzone na grupie 20 osób, które testowały produkt w warunkach domowych przez okres 2 tygodni.</p>
          </div>
        </section>
        <section v-if="product.usageGuidelines" class="section sectionUsageGuidelines">
          <div class="container1400">
            <div>
              <h1>Użytkowanie Świec</h1>
              <h3>Użytkowanie Świec sojowych MyAURA</h3>
            </div>
            <p>{{ product.usageGuidelines }}</p>
          </div>
        </section>
        <section v-if="product.type === 'mgiełka'" class="section sectionComposition" id="section-3">
          <div class="container1400">
            <div>
              <h3>Składniki</h3>
              <h1>Działanie składników w recepturze</h1>
            </div>
            <p>Nasze mgiełki nie tylko pięknie wyglądają i pięknie pachną, ale również zawierają składniki pozytywnie dzałające na skórę.</p>
            <h6>Nawilżanie</h6>
            <p>Jednym ze składników o działaniu nawilżającym jest gliceryna. To jeden z najpopularniejszych, najpowszechniej stosowanych składników kosmetyków - jest nietoksyczna, pozytywnie wpływa na konsystencję i trwałość kosmetyków, a do tego ma właściwości nawilżające i świetnie oddziałuje na kondycję skóry.</p>
            <p>Druga substancja nawilżająca, (Saccharide Isomerate, Aqua, Citric Acid, Sodium Citrate), przez kosmetologów nazywana &quot;magnesem wilgoci&quot;, jest w 100 proc. naturalnym składnikiem pochodzenia roślinnego, posiadającym certyfikaty ECOCERT i NaTrue. Zapewnia skórze natychmiastowe nawilżenie, natomiast stosowana systematycznie zapewnia trwały efekt nawilżenia.</p>
            <h6>Kontrola zapachu potu</h6>
            <p>Nieprzyjemny zapach skóry powstaje, gdy naturalnie bezwonny pot ulega rozkładowi przez mikroorganizmy, głównie przez bakterie gram-dodatnie występujące na skórze (np. Staphylococcus epidermidis, Corynebacterium xerosis, Propionibacterium acnes). Użyta w mgiełce mieszanina etyloheksylogliceryny (mającej właściwości dezodoryzujące i pielęgnujące), oraz przeciwdrobnoustrojowego chlorowodorku oktenidyny zapewnia - już przy bardzo niskich stężeniach - bardzo dobrą skuteczność hamowania wzrostu bakterii powodujących powstawanie zapachu potu. Jednocześnie jej działanie jest selektywne i nie wywiera znaczącego wpływu na naturalną mikroflorę skóry.</p>
          </div>
        </section>
        <section v-if="product.ingredients && product.type === 'mgiełka'" class="section sectionIngredients" id="section-4">
          <div class="container1400">
            <div>
              <h3>Skład</h3>
              <h1>Składniki / Ingredients</h1>
            </div>
            <p>{{ product.ingredients }}</p>
          </div>
        </section>
      </div>
    </transition>
    <div class="nonExistant" v-if="!this.exists">
      <h1>{{ this.message }}</h1>
    </div>
  </div>
</template>

<script>
import AddToCartButton from "@/components/AddToCartButton.vue";
import BuyNowButton from "@/components/BuyNowButton.vue";

export default {
  data() {
    return {
      productId: this.$route.params.id,
      exists: false,
      loading: true,
      product: {},
      currentSlide: 0,
      slideAmount: 0,
      message: "",

      color: "rgb(252, 201, 89)",
      colorLowOp: "rgb(252, 201, 89)",
      colors: {
        fire: "#c50000",
        earth: "#4b7c0f",
        wind: "#ffd006",
        water: "#66abf0",
      },
    };
  },
  methods: {
    async getProduct() {
      const req = await fetch(`/api/products/${this.productId}`, {
        method: "GET",
      });

      const res = await req.json();

      if (req.status === 200) {
        this.product = res.data;
        this.exists = true;
        this.loading = false;
        this.color = this.colors[res.data.element];
        this.colorLowOp = `${this.colors[res.data.element]}30`;
        document.title = `MyAURA - ${res.data.name}`;
      } else if (req.status === 404) {
        this.message = res.message;
      }
    },
    addToCart(amount = 1) {
      this.$emit("addToCart", this.product._id, amount);
    },
  },
  created() {
    document.title = `MyAURA`;
    this.getProduct();
  },
  watch: {
    $route() {
      this.productId = this.$route.params.id;
      document.title = `MyAURA`;
      this.getProduct();
    },
  },
  components: {
    AddToCartButton,
    BuyNowButton,
  },
};
</script>

<style scoped>
/* ========================================================================== */
/* GENERAL ========================================================== GENERAL */
/* ========================================================================== */

::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.container1400 {
  margin: 0 auto;
  max-width: 1440px;
  padding: 0 20px;
}

.section {
  padding: 100px 0;
  margin: 20px 0;
  width: 100%;
}
.section h3 {
  font-size: 20px;
  color: v-bind("color");
}
.section h1 {
  color: var(--font-color);
  font-size: 35px;
}

/* ========================================================================== */
/* HEADER ============================================================ HEADER */
/* ========================================================================== */

header {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 40px;

  display: flex;
  flex-direction: column;
}

header .top {
  display: grid;
  grid-template-columns: 80px auto;
  align-items: center;
  padding-bottom: 30px;
}
header .top img {
  width: 75%;
  aspect-ratio: 1/1;
}
header .top h1 {
  font-size: 2rem;
  font-weight: 600;
  color: #121212;
  line-height: 2.5rem;
}
header .top h2 {
  font-size: 1rem;
  font-weight: 400;
  color: #3c3c3c;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

header .center {
  display: grid;
  grid-template-columns: auto 400px;
  gap: 20px;
  /* height: calc(100vh - 262px); */
  min-height: 600px;
  max-height: 1000px;
}
header .center .slideshow {
  position: relative;
  background-color: v-bind("colorLowOp");
  padding: 10px;
  border-radius: 5px;
}
header .center .mainImgCont {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
header .center .mainImgCont img {
  max-height: 600px;
  max-width: 100%;
}
header .center .slideshow .previews {
  position: absolute;
  top: 10px;
  right: 10px;

  display: flex;
  flex-direction: column;
  gap: 10px;
}
header .center .slideshow .previews .preview {
  height: 80px;
  aspect-ratio: 1/1;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ebebeb;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.1s;
  cursor: pointer;
}
header .center .slideshow .previews .preview:hover {
  transform: translateX(-5px);
}
header .center .slideshow .previews img {
  max-height: 100%;
  max-width: 100%;
}
header .center .info {
  padding: 10px 15px 15px 15px;
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}
header .center .info h3 {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 15px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}
header .center .info h3 svg {
  stroke-width: 1.6rem;
  stroke: #212121;
  fill: transparent;
  height: 25px;
}
header .center .info ul {
  margin: 0 0 20px 0;
  list-style: none;
}
header .center .info li svg {
  stroke-width: 2rem;
  stroke: #292929;
  height: 25px;
}
header .center .info li .left {
  display: flex;
  align-items: center;
  gap: 10px;
}
header .center .info li p {
  font-size: 15px;
  font-weight: 400;
  color: #2b2b2b;
}
header .center .info li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 10px 0;
  font-size: 15px;
  font-weight: 400;
  color: #2b2b2b;
}
header .center .info .shop {
  margin: 10px 0 5px 0;
  padding: 10px 0 0 0;

  display: flex;
  justify-content: space-between;
  align-items: center;

  border-top: 1px dotted #c4c4c4;
}
header .center .info .shop h2 {
  font-size: 2rem;
  font-weight: 600;
  color: #121212;
}
/* header .center .info .shop button {
  height: 50px;
} */
header .center .info .actions {
  margin: 5px 0 10px 0;
  padding: 0 0 10px 0;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;

  border-bottom: 1px dotted #c4c4c4;
}
header .center .info .actions button {
  height: 50px;
  width: 100%;
}
header .center .info p {
  font-size: 15px;
  font-weight: 400;
  color: #393939;
}
header .center .info .flexBottom {
  margin-top: auto;
}

/* ========================================================================== */
/* BRIEF ============================================================== BRIEF */
/* ========================================================================== */

.sectionBriefInfo .howToUse,
.sectionBriefInfo .benefitInfo {
  margin: 0 auto;
  padding: 0 40px;
  text-align: center;
}

.sectionBriefInfo .howToUse {
  margin-bottom: 160px;
}

.sectionBriefInfo .benefitInfo {
  max-width: 1200px;
  margin-bottom: 0;
}

.sectionBriefInfo h1 {
  font-size: 2rem;
  font-weight: 600;
  color: #121212;
}

.sectionBriefInfo .howToUse ul {
  width: 100%;
  padding: 40px 0;
  gap: 40px;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  list-style: none;
  text-align: left;
}

.sectionBriefInfo .howToUse ul li {
  padding: 20px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 15px 15px 30px #00000014;
  border-radius: 5px;
}

.sectionBriefInfo .howToUse .span3 {
  grid-column: span 3;
}
.sectionBriefInfo .howToUse .span2 {
  grid-column: span 2;
}

.sectionBriefInfo .howToUse li h2 {
  font-size: 3rem;
  font-weight: 200;
  color: #a2a2a2;
  line-height: 100%;
  margin-bottom: 1rem;
}

.sectionBriefInfo .howToUse li p {
  font-size: 1rem;
  font-weight: 400;
  color: #393939;
}

.sectionBriefInfo .benefitInfo ul {
  margin: 40px;
  list-style: none;
  position: relative;

  height: 500px;
}

.sectionBriefInfo .benefitInfo li {
  position: absolute;
  padding: 20px;
  text-align: left;
  background-color: #fff;
  box-shadow: 15px 15px 30px #00000014;
  border-radius: 5px;

  min-width: 450px;
  max-width: 550px;
  font-size: 1rem;
  font-weight: 400;
  color: #393939;

  display: flex;
  justify-content: left;
  align-items: center;
  gap: 10px;
}

.sectionBriefInfo .benefitInfo li img {
  width: 40px;
  height: 40px;
}
.sectionBriefInfo .benefitInfo li p {
  white-space: normal;
  word-break: keep-all;
  word-wrap: break-word;
}

.sectionBriefInfo .benefitInfo .benefit0 {
  top: 0;
  left: 0;
}
.sectionBriefInfo .benefitInfo .benefit1 {
  top: 25%;
  right: 0;
}
.sectionBriefInfo .benefitInfo .benefit2 {
  top: 50%;
  left: 5%;
}
.sectionBriefInfo .benefitInfo .benefit3 {
  top: 75%;
  right: 5%;
}

/* ========================================================================== */
/* PHRASE ============================================================ PHRASE */
/* ========================================================================== */

.phrase {
  color: white;
  background-color: v-bind("color");
  margin: 40px 0;
  padding: 40px 0;
  text-align: center;
  font-size: 20px;
}

/* ========================================================================== */
/* CONSUMER TESTS ============================================ CONSUMER TESTS */
/* ========================================================================== */

.sectionConsumerTests p {
  margin-bottom: -40px;
}
.testFade {
  margin: 35px -10px;
  position: relative;
}
.testFade::after,
.testFade::before {
  content: "";
  position: absolute;
  top: 0;
  width: 10px;
  height: 100%;
}
.testFade::after {
  right: 0;
  background-image: linear-gradient(90deg, rgba(59, 19, 68, 0), rgb(243, 243, 243));
}
.testFade::before {
  left: 0;
  background-image: linear-gradient(-90deg, rgba(0, 0, 0, 0), rgb(243, 243, 243));
}
.tests {
  display: grid;
  grid-template-columns: repeat(13, 300px);
  grid-column-gap: 20px;

  padding: 15px 10px;
  overflow-x: scroll;
}
.test {
  width: 100%;
  min-height: 440px;

  background-color: #fff;
  border-radius: 10px;

  padding: 20px;
}
.tests h1 {
  font-size: 80px;
  line-height: 80px;
  font-weight: 600;
  color: rgb(40, 40, 40);
  margin-bottom: 20px;
}
.tests h1 span {
  font-size: 56px;
}
.tests p {
  font-size: 18px;
  font-weight: 400;
  color: rgb(57, 57, 57);
}

/* ========================================================================== */
/* COMPOSITION ================================================== COMPOSITION */
/* ========================================================================== */

.sectionComposition {
  background-color: #f4f4f4;
  border-bottom: 1px solid gray;
  margin-bottom: 0;
  padding: 40px;
}
.sectionComposition h1 {
  margin-bottom: 30px;
}
.sectionComposition p {
  margin: 30px 0;
  font-size: 17px;
  font-weight: 400;
}
.sectionComposition h6 {
  font-size: 20px;
  font-weight: 600;
}

/* ========================================================================== */
/* USAGE GUIDELINES ======================================== USAGE GUIDELINES */
/* ========================================================================== */

.sectionUsageGuidelines {
  margin-bottom: 100px;
}

.sectionUsageGuidelines p {
  margin: 30px 0;
  font-size: 17px;
  font-weight: 400;
}

/* ========================================================================== */
/* INGREDIENTS ================================================== INGREDIENTS */
/* ========================================================================== */

.sectionIngredients {
  background-color: #f4f4f4;
  margin-top: 0;
}

.sectionIngredients p {
  color: black;
  margin: 30px 0;
  font-size: 17px;
  font-weight: 400;
}

/* ========================================================================== */
/* MEDIA QUERIES ============================================== MEDIA QUERIES */
/* ========================================================================== */

@media only screen and (max-width: 1100px) {
  header .top h2 {
    font-size: 0.9rem;
  }
  header .center {
    grid-template-columns: auto 300px;
    max-height: 100vh;
  }
  header .center .info h3 svg {
    display: none;
  }

  .sectionBriefInfo .howToUse ul {
    padding: 20px 0;
    gap: 20px;

    grid-template-columns: repeat(3, 1fr);
  }
  .sectionBriefInfo .howToUse .span3 {
    grid-column: span 2;
  }
  .sectionBriefInfo .howToUse .span2 {
    grid-column: span 1;
  }
}

@media only screen and (max-width: 1000px) {
  .section {
    padding: 80px 0;
  }
  .section h3 {
    font-size: 20px;
  }
  .section h1 {
    font-size: 36px;
  }

  .tests {
    grid-template-columns: repeat(13, 260px);
  }
  .test {
    min-height: 400px;

    padding: 1rem;
  }
  .tests h1 {
    font-size: 70px;
    line-height: 70px;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  .tests h1 span {
    font-size: 50px;
  }
  .tests p {
    font-size: 16px;
    font-weight: 400;
    color: rgb(57, 57, 57);
  }
}

@media only screen and (max-width: 900px) {
  header .center {
    grid-template-columns: 100%;
    max-height: unset;
    height: unset;
  }
  header .center .info h3 svg {
    display: none;
  }
  header .center .info h3 svg {
    display: block;
  }

  .sectionBriefInfo .howToUse {
    padding: 0 20px;
  }
  .sectionBriefInfo .howToUse ul {
    padding: 20px 0;
    grid-template-columns: repeat(2, 1fr);
  }
  .sectionBriefInfo .howToUse .span3 {
    grid-column: span 1;
  }

  .sectionBriefInfo .benefitInfo ul {
    margin: 40px 0;
    width: 100%;
    height: unset;
  }

  .sectionBriefInfo .benefitInfo li {
    position: static;
    padding: 20px;
    margin: 20px 0;
    width: 100%;
    min-width: unset;
    max-width: unset;
  }
  .sectionBriefInfo .benefitInfo li img {
    width: 30px;
    height: 30px;
  }
}

@media only screen and (max-width: 750px) {
  header .top h1 {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  header .top h2 {
    font-size: 0.8rem;
  }

  .sectionBriefInfo .howToUse ul {
    gap: 20px;

    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 500px) {
  .section {
    min-height: 60vh;
  }
  .section h3 {
    font-size: 15px;
  }
  .section h1 {
    font-size: 22px;
  }

  header {
    padding: 40px 10px;
  }
  header .top h1 {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
  header .top h2 {
    font-size: 0.9rem;
  }

  header .center .slideshow .previews {
    position: absolute;
    top: unset;
    bottom: 10px;
    right: unset;
    left: 10px;

    flex-direction: row;
  }
  header .center .slideshow .previews .preview {
    height: 70px;
  }
  header .center .slideshow .previews .preview:hover {
    transform: translateY(-5px);
  }

  header .center .info h3 {
    font-size: 18px;
  }
  header .center .info h3 svg {
    stroke-width: 1.3rem;
    height: 20px;
  }
  header .center .info li svg {
    stroke-width: 2rem;
    stroke: #292929;
    height: 20px;
  }
  header .center .info li .left {
    gap: 8px;
  }
  header .center .info li p {
    font-size: 13px;
  }
  header .center .info li {
    font-size: 13px;
  }
  header .center .info p {
    font-size: 13px;
  }

  .phrase {
    font-size: 14px;
  }

  .tests {
    grid-template-columns: repeat(13, 220px);
  }
  .test {
    min-height: 300px;

    padding: 0.8rem;
  }
  .tests h1 {
    font-size: 60px;
    line-height: 60px;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  .tests h1 span {
    font-size: 45px;
  }
  .section-2 p {
    font-size: 10px;
  }
  .tests p {
    font-size: 14px;
    font-weight: 400;
    color: rgb(57, 57, 57);
  }

  .sectionComposition h1 {
    margin-bottom: 20px;
  }
  .sectionComposition p {
    margin: 15px 0;
    font-size: 10px;
  }
  .sectionUsageGuidelines p {
    margin: 15px 0;
    font-size: 10px;
  }
  .sectionComposition h6 {
    font-size: 15px;
  }

  .sectionBriefInfo .benefitInfo {
    padding: 0 20px;
  }
  .sectionBriefInfo .howToUse {
    padding: 0;
  }
  .sectionBriefInfo .howToUse ul li {
    padding: 20px;
  }
  .sectionBriefInfo h2 {
    font-size: 2rem !important;
  }
  .sectionBriefInfo p {
    font-size: 0.8rem !important;
  }
}

@media only screen and (max-width: 450px) {
  header .top {
    grid-template-columns: 50px auto;
    gap: 10px;
  }
  header .top img {
    width: 100%;
  }
  header .top h1 {
    font-size: 1rem;
    line-height: 1.4rem;
  }
  header .top h2 {
    font-size: 0.75rem;
  }
}

@media only screen and (max-width: 370px) {
  .sectionBriefInfo .howToUse ul li {
    padding: 20px;
  }
}
</style>

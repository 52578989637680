<template>
  <div class="progress">
    <div class="bar">
      <div class="subBar" :style="`width: ${step * 33}%`"></div>
    </div>
    <ul class="labels">
      <li :class="step >= 0 && 'completed'">Koszyk</li>
      <li :class="step >= 1 && 'completed'">Dostawa</li>
      <li :class="step >= 2 && 'completed'">Płatność</li>
      <li :class="step >= 3 && 'completed'">Wysyłka</li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    step: Number,
  },
};
</script>

<style scoped>
.progress {
  position: relative;
  height: 40px;
}
.bar {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-50%);

  height: 3px;
  background-color: #b4b4b4;
  z-index: -1;
}
.subBar {
  height: 5px;
  background-color: var(--action-color);
  transform: translateY(-1px);

  transition: 0.3s;
}
.progress .labels {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-50%);

  display: flex;
  justify-content: space-between;
  align-content: center;
  list-style: none;
}
.progress .labels li {
  padding: 4px 18px;
  border-radius: 40px;
  background-color: #b4b4b4;

  font-size: 15px;
  font-weight: 500;
  color: #ffffff;

  transition: 0.2s;
}
.progress .labels .completed {
  background-color: var(--action-color);
}

@media only screen and (max-width: 550px) {
  .progress {
    height: 30px;
  }
  .bar {
    height: 4px;
  }
  .subBar {
    height: 4px;
    transform: translateY(-0.5px);
  }
  .progress .labels li {
    padding: 4px 12px;
    font-size: 11px;
    font-weight: 400;
  }
  .progress .labels .completed {
    background-color: var(--action-color);
  }
}
</style>

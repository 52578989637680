<template>
  <div class="panel">
    <ErrorLogin v-if="!this.token" @openLoginPrompt="this.$emit('openLoginPrompt')" />
    <div v-if="this.token" class="account">
      <h1 class="welcome">Witaj, {{ user.name }}</h1>
      <h2 class="contact">{{ user.email }}</h2>
      <div class="grid">
        <div class="el">
          <div class="heading">
            <img src="/assets/svg/time-outline.svg" alt="Clock" />
            <h3>AKTUALNE ZAMÓWIENIE</h3>
          </div>
          <div class="recentOrder" v-if="user.recentOrder?.price">
            <div class="recentStatus">
              <h1>Status: {{ user.recentOrder.status }}</h1>
              <img :src="`/assets/svg/${user.recentOrder.status}.svg`" alt="Order Status Icon" />
            </div>
            <div class="detailsRecent">
              <h3>{{ user.recentOrder.productString }}</h3>
              <h2>
                {{ `${user.recentOrder.price}`.slice(0, -2) + "," + `${user.recentOrder.price}`.slice(-2) }}zł <span v-if="user.recentOrder.discountCode">-> {{ `${user.recentOrder.price - user.recentOrder.totalAmountDiscounted}`.slice(0, -2) + "," + `${user.recentOrder.price - user.recentOrder.totalAmountDiscounted}`.slice(-2) }}zł</span>
              </h2>
              <p>
                {{
                  new Intl.DateTimeFormat("PL", {
                    hour: "numeric",
                    minute: "numeric",
                    day: "numeric",
                    month: "long",
                    year: "2-digit",
                    weekday: "long",
                  }).format(new Date(user.recentOrder.date))
                }}
              </p>
            </div>
          </div>
          <div class="orderEmpty" v-if="!user.recentOrder?.price">
            <img src="/assets/svg/order.svg" alt="Order" />
            <h6>Brak zamówienia</h6>
          </div>
        </div>
        <div class="el">
          <div class="heading">
            <img src="/assets/svg/list-outline.svg" alt="List" />
            <h3>HISTORIA ZAMÓWIEŃ</h3>
          </div>
          <div class="orderHistory" v-if="user.orders?.length !== 0">
            <div class="order" v-for="(order, i) of user.orders" :key="i">
              <h3>
                {{
                  new Intl.DateTimeFormat("PL", {
                    hour: "numeric",
                    minute: "numeric",
                    day: "numeric",
                    month: "long",
                    year: "2-digit",
                    weekday: "long",
                  }).format(new Date(order.date))
                }}
                - {{ order.status }}
              </h3>
              <h1>
                {{ `${order.price}`.slice(0, -2) + "," + `${order.price}`.slice(-2) }}zł <span v-if="order.discountCode">-> {{ `${order.price - order.totalAmountDiscounted}`.slice(0, -2) + "," + `${order.price - order.totalAmountDiscounted}`.slice(-2) }}zł</span>
              </h1>
              <h2>{{ order.productString }}</h2>
            </div>
          </div>
          <div class="orderEmpty" v-if="user.orders?.length === 0">
            <img src="/assets/svg/history.svg" alt="Order History" />
            <h6>Brak zamówień</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorLogin from "../components/ErrorLogin.vue";

export default {
  data() {
    return {
      product: {},
      orderUser: {},
    };
  },
  components: {
    ErrorLogin,
  },
  props: {
    token: String,
    user: Object,
  },
  mounted() {
    // Double refresh
    this.$emit("updateOrderData");
  },
  created() {
    document.title = `MyAURA - Panel klienta`;
  },
};
</script>

<style scoped>
.account {
  max-width: 1640px;
  padding: 20px;
  margin: 0 auto;
  min-height: calc(100vh - var(--nav-height));
  padding-bottom: 100px;
}
.welcome {
  margin-top: 30px;
  font-size: 55px;
  line-height: 65px;
  font-weight: 700;
}
.contact {
  margin-bottom: 55px;
  font-size: 23px;
  font-weight: 400;
  color: #4545457d;
}

.grid {
  display: grid;
  grid-template-columns: auto 400px;
  grid-gap: 40px;
}
.el {
  background-color: white;
  padding: 20px;
  height: fit-content;
}
.heading {
  display: flex;
  align-items: center;
  padding-bottom: 1rem;

  border-bottom: 1px dashed rgb(158, 158, 158);
}
.heading img {
  width: 35px;
  aspect-ratio: 1/1;
  margin-right: 1rem;
}
.heading h3 {
  font-size: 24px;
  font-weight: 500;
}

.recentStatus {
  display: flex;
  align-items: center;
}
.recentStatus img {
  width: 45px;
  aspect-ratio: 1/1;
}
.recentStatus h1 {
  font-size: 40px;
  font-weight: 600;

  margin: 50px 1rem 50px 0;
}
.detailsRecent h2 {
  font-size: 32px;
  font-weight: 600;
  color: rgb(44, 44, 44);
}
.detailsRecent h3 {
  font-size: 20px;
  font-weight: 400;
  color: rgb(128, 128, 128);
}
.detailsRecent p {
  font-size: 16px;
  font-weight: 300;
  color: rgb(70, 70, 70);
}

.order {
  margin: 2rem 0;
}
.order h1 {
  font-size: 28px;
  font-weight: 600;
  color: rgb(45, 45, 45);
}
.order h2 {
  font-size: 18px;
  font-weight: 600;
  color: rgb(66, 66, 66);
}
.order h3 {
  font-size: 16px;
  color: rgb(111, 111, 111);
  font-weight: 400;
}

.orderEmpty {
  padding: 60px 50px 50px 50px;
  text-align: center;
}
.orderEmpty img {
  width: 70px;
  aspect-ratio: 1/1;
}
.orderEmpty h6 {
  color: rgb(158, 158, 158);
  font-size: 26px;
  font-weight: 400;
}

@media only screen and (max-width: 1000px) {
  .grid {
    grid-template-columns: 1fr;
  }
}
@media only screen and (max-width: 550px) {
  .account {
    padding: 15px;
    padding-bottom: 60px;
  }
  .welcome {
    margin-top: 25px;
    font-size: 45px;
    line-height: 55px;
  }
  .contact {
    margin-bottom: 45px;
    font-size: 20px;
  }

  .el {
    padding: 15px;
  }
  .heading img {
    width: 30px;
    margin-right: 0.75rem;
  }
  .heading h3 {
    font-size: 21px;
  }

  .order {
    margin: 2rem 0;
  }
  .order h1 {
    font-size: 24px;
  }
  .order h2 {
    font-size: 15px;
  }
  .order h3 {
    font-size: 15px;
  }

  .recentStatus img {
    width: 40px;
  }
  .recentStatus h1 {
    font-size: 27px;

    margin: 30px 1rem 30px 0;
  }
  .detailsRecent h2 {
    font-size: 24px;
  }
  .detailsRecent h3 {
    font-size: 15px;
  }
  .detailsRecent p {
    font-size: 13px;
    font-weight: 300;
    color: rgb(70, 70, 70);
  }
}
</style>

<template>
  <div class="notFound">
    <h1>Ta podstrona nie istnieje</h1>
    <button @click="this.$router.push('/')">Powrót na stronę główną</button>
  </div>
</template>

<script>
export default {
  created() {
    document.title = `MyAURA - Nie znaleziono`;
  },
};
</script>

<style scoped>
.notFound {
  min-height: calc(100vh - var(--nav-height-mobile));

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
h1 {
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 40px;
  text-align: center;
}
button {
  padding: 1rem 2rem;
  border-radius: 3px;
  font-size: 24px;

  font-weight: 700;
  transition: 0.1s;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgb(70, 67, 255);
  color: white;
  border: 2px solid rgb(55, 52, 247);
}
button:hover {
  background-color: rgb(55, 52, 247);
}

@media only screen and (max-width: 1000px) {
  .notFound {
    min-height: calc(100vh - 67px);
  }
}
@media only screen and (max-width: 380px) {
  h1 {
    font-size: 40px;
    margin-bottom: 30px;
  }
  button {
    padding: 1rem;
    font-size: 22px;
  }
}
</style>

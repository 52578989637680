<template>
  <div class="wrapper">
    <div v-if="this.products?.length" class="products">
      <div v-for="(product, i) of this.products" :key="i" class="product" :style="`background-color: ${i % 2 === 0 ? 'rgb(220, 220, 220)' : 'white'}`">
        <div v-for="entry of Object.entries(product)" :key="entry[0]">
          <h3>{{ entry[0] }}</h3>
          <p>{{ entry[1] }}</p>
        </div>
      </div>
    </div>
    <div v-if="!this.products?.length" class="products"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      products: [],
    };
  },
  props: {
    token: String,
  },
  methods: {
    async getProducts() {
      const req = await fetch("/api/admin/products", {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${this.token}`,
        },
      });
      const res = await req.json();
      this.products = res.products;
    },
  },
  mounted() {
    this.getProducts();
  },
  created() {
    document.title = `MyAURA`;
  },
};
</script>

<style scoped>
.wrapper {
  min-height: calc(100vh - var(--nav-height-mobile));

  user-select: text;
  -webkit-user-select: text;
}
.product {
  padding: 20px;
}
li {
  list-style: none;
}
</style>

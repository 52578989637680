<template>
  <!-- This the full background -->
  <div class="window">
    <div v-show="!this.selected" class="selection">
      <div class="title">
        <h1>Wybierz typ zestawu</h1>
        <h3>W każdym zestawie można dowolnie wybierać i mieszać żywioły.</h3>
        <img src="/assets/img/christmas-box.webp" alt="Christmas box" />
      </div>
      <ul>
        <li v-for="set of this.setTypes" :key="set.name" @click="selectSet(set.name)">
          <h3>{{ set.name }} - {{ `${set.price}`.slice(0, -2) + "," + `${set.price}`.slice(-2) }}zł</h3>
          <p>Zestaw składa się z {{ set.productLines.map((l) => `${set[l]} ${set[l] > 1 ? this.products[l].text[1] : this.products[l].text[0]}`).join(", ") }}.</p>
          <!-- prettier-ignore -->
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><polyline points="268 112 412 256 268 400" style="stroke-linecap:round;stroke-linejoin:round"/><line x1="392" y1="256" x2="100" y2="256" style="stroke-linecap:round;stroke-linejoin:round"/></svg>
        </li>
      </ul>
    </div>
    <div v-show="this.selected" class="creator">
      <!-- This the product tray -->
      <div class="products">
        <h2>Dostępne Produkty</h2>
        <div class="columns">
          <div v-for="[id, pType] of Object.entries(this.products)" :key="pType" :class="['column', this.set[id] || 'inactive']">
            <h3>{{ pType.title }}</h3>
            <ul>
              <li v-for="product of pType.products" :key="product._id" :class="product.avalibility === 'Wkrótce Dostępne' && 'inactive'" :data-id="product._id" @click="this.getWidth() <= 1000 && addItem(product)" @dblclick="addItem(product)">
                <img :src="product.images[0]" :alt="`${product.name} image`" />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="center">
        <!-- prettier-ignore -->
        <svg viewBox="0 0 581 127" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 98.5C236.5 7 292.5 81 580 52.5M580 52.5L496 126.5M580 52.5L483.5 1" /></svg>
        <p>Kliknij, przytrzymaj i przeciągnij produkt do panelu po prawej stronie</p>
      </div>
      <!-- This the set contents -->
      <div class="set">
        <div class="top">
          <h2>Twój {{ this.set.name }}</h2>
          <h4 v-if="this.getWidth() > 1000">
            Wybierz produkty w twoim zestawie. <span ref="listAdd" v-show="this.set.mgiełki || this.set.świece">Przeciągnij z produktów po lewej{{ `${set.mgiełki ? `${set.mgiełki > 1 ? ` ${set.mgiełki} mgiełki` : " 1 mgiełkę"}` : ""}${set.świece && set.mgiełki ? " i" : ""}${set.świece ? `${set.świece > 1 ? ` ${set.świece} świece` : " 1 świecę"}` : ""}` }}.</span>
            <span v-show="!this.set.mgiełki && !this.set.świece">Kliknij w produkt, aby go usunąć albo dodaj do koszyka.</span>
          </h4>
          <h4 v-if="this.getWidth() <= 1000">
            Wybierz produkty w twoim zestawie. <span ref="listAdd" v-show="this.set.mgiełki || this.set.świece">Kliknij w{{ `${set.mgiełki ? `${set.mgiełki > 1 ? ` ${set.mgiełki} mgiełki` : " 1 mgiełkę"}` : ""}${set.świece && set.mgiełki ? " i" : ""}${set.świece ? `${set.świece > 1 ? ` ${set.świece} świece` : " 1 świecę"}` : ""}` }}, żeby skompletować zestaw.</span>
            <span v-show="!this.set.mgiełki && !this.set.świece">Kliknij w produkt, aby go usunąć albo dodaj do koszyka.</span>
          </h4>
          <ul v-show="!this.dragging">
            <li v-for="product of this.set.products" :key="product.product._id" @click="removeProduct(product.product._id)">
              <img :src="product.product.images[0]" :alt="`${product.product.name} image`" />
              <div>
                <h6>{{ product.product.name }}</h6>
                <p>{{ product.quantity }}x</p>
              </div>
            </li>
          </ul>
        </div>
        <div class="dragIn" ref="dragBox" v-show="this.dragging">
          <img src="/assets/svg/add-circle-outline.svg" alt="Add Product Icon" />
          <p>Przeciągnij produkt tutaj</p>
        </div>
        <div class="bottom">
          <h3>{{ `${this.set.price}`.slice(0, -2) + "," + `${this.set.price}`.slice(-2) }}zł</h3>
          <button @click="submitSet">Dodaj do koszyka</button>
        </div>
      </div>

      <div class="follower" ref="follower">
        <img />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dragging: false,
      selected: false,

      products: {
        mgl: {
          title: "Mgiełki",
          text: ["Mgiełki", "Mgiełek"],
          products: [],
        },
        mgs: {
          title: "Mgiełki Mini",
          text: ["Mgiełki Mini", "Mgiełek Mini"],
          products: [],
        },
        swn: {
          title: "Świece",
          text: ["Świecy", "Świec"],
          products: [],
        },
      },
      productsUnsorted: [],

      set: {
        products: [],
      },
    };
  },
  props: {
    setTypes: Array,
  },
  methods: {
    getWidth() {
      return window.innerWidth;
    },
    submitSet() {
      if (this.set.productLines.some((l) => this.set[l] !== 0)) {
        this.remindUserToAdd();
      } else {
        let string = this.set.products.map((p) => `${p.quantity}>${p.product._id}`).join(",");
        this.$emit("addToCart", `SET:${this.set.name}:${string}`, 1);
      }
    },
    selectSet(name) {
      const set = this.setTypes.find((s) => s.name === name);
      if (!set) return;
      set.productLines.forEach((l) => {
        this.set[l] = set[l] || 0;
      });
      this.set.productLines = set.productLines;
      this.set.price = set.price;
      this.set.name = set.name;
      this.set._id = set._id;
      this.selected = true;
      document.title = `MyAURA - ${name}`;
    },
    addItem(product) {
      if (!product.productLine) return;
      if (!this.set[product.productLine]) return;
      if (this.set[product.productLine] === 0) return;
      this.set[product.productLine]--;
      const inSet = this.set.products.find((entry) => entry.product._id === this.$refs.follower.dataset.id);
      if (!inSet) this.set.products.push({ quantity: 1, product });
      if (inSet) inSet.quantity++;
    },
    removeProduct(id) {
      for (let i = 0; i < this.set.products.length; i++) {
        if (this.set.products[i].product._id === id) {
          this.set[this.set.products[i].product.productLine]++;
          if (this.set.products[i].quantity === 1) {
            this.set.products.splice(i, 1);
          } else {
            this.set.products[i].quantity--;
          }
        }
      }
    },
    remindUserToAdd() {
      this.$refs.listAdd.style.color = "red";
      setTimeout(() => (this.$refs.listAdd.style.color = "black"), 500);
      setTimeout(() => (this.$refs.listAdd.style.color = "red"), 1000);
      setTimeout(() => (this.$refs.listAdd.style.color = "black"), 1500);
      setTimeout(() => (this.$refs.listAdd.style.color = "red"), 2000);
      setTimeout(() => (this.$refs.listAdd.style.color = "black"), 3000);
    },
    followCursor(e) {
      e.preventDefault();
      this.$refs.follower.style.top = `${e.y}px`;
      this.$refs.follower.style.left = `${e.x}px`;
    },
    async getProducts() {
      try {
        const res = await fetch("/api/products", {
          method: "GET",
        });

        if (res.status === 200) {
          const data = await res.json();
          this.productsUnsorted = data.data;
          data.data.forEach((prod) => {
            this.products[prod.productLine].products.push(prod);
          });
          // Loading the set from the URL
          this.checkSetUrl();
        } else {
          this.$route.push("/");
        }
      } catch (err) {
        console.error(err);
      }
    },
    checkSetUrl() {
      if (this.$route.query.set) {
        this.set.products = [];
        const [type, name, products] = this.$route.query.set.split(":");
        console.log(type);
        this.selectSet(name);
        this.selected = true;
        products.split(",").forEach((p) => {
          for (let i = p.split(">")[0] - 1; i >= 0; i--) {
            this.addItem(this.productsUnsorted.find((el) => el._id === p.split(">")[1]));
          }
        });
      } else {
        this.selected = false;
        this.set = {
          products: [],
        };
      }
    },
  },
  created() {
    this.getProducts();
    document.title = "MyAURA - Konfigurator Zestawów";
  },
  mounted() {
    // Selecting the supported div
    const screen = document.querySelector(".creator");
    // Handling the start of the drag
    screen.addEventListener("mousedown", (e) => {
      e.preventDefault();
      if (!e.target.closest("li")) return;
      const li = e.target.closest("li");
      if (!li.dataset.id) return;
      this.dragging = true;
      // Copy image somehow instead?
      this.$refs.follower.querySelector("img").src = li.querySelector("img").src;
      this.$refs.follower.dataset.id = li.dataset.id;
      // Forcing it to move
      screen.addEventListener("mousemove", this.followCursor);
    });
    // Handling letting go of the product
    screen.addEventListener("mouseup", (e) => {
      e.preventDefault();
      this.dragging = false;
      screen.removeEventListener("mousemove", this.followCursor);
      this.$refs.follower.style.left = "-100%";

      const box = this.$refs.dragBox.getBoundingClientRect();
      if (e.x >= box.x && e.x <= box.x + box.width && e.y >= box.y && e.y <= box.y + box.height) {
        const product = this.productsUnsorted.find((p) => p._id === this.$refs.follower.dataset.id);
        this.addItem(product);
      }
    });
  },
  watch: {
    $route() {
      this.checkSetUrl();
    },
  },
};
</script>

<style scoped>
.window {
  min-height: calc(100vh - var(--nav-height-mobile));

  width: 100%;
  max-width: 1640px;
  padding: 0 20px;
  margin: 0 auto 50px auto;
}

.selection {
  margin-top: 75px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 100px;
}
.title h1 {
  font-size: 45px;
  font-weight: 700;
}
.title h3 {
  font-size: 18px;
  font-weight: 500;
  color: #2e2e2e;
  max-width: 90%;
}
.title img {
  width: 40%;
  margin-left: 20%;
}
.selection ul li {
  position: relative;
  margin: 20px 0;
  padding: 10px 10px 10px 15px;
  border-left: 2px solid var(--main-color-1-dimm);
  background-color: rgb(255, 255, 255);
  list-style: none;
  cursor: pointer;
}
.selection ul li:hover {
  background-color: #f2f2f2;
}
.selection ul li h3 {
  font-size: 20px;
  font-weight: 600;
}
.selection ul li p {
  font-size: 15px;
  font-weight: 400;
}
.selection ul li svg {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  height: 40px;
  width: auto;
  stroke: #2a2a2a;
  stroke-width: 32px;
  fill: none;
  transition: 0.2s;
}
.selection ul li:hover svg {
  right: 20px;
}

.creator {
  margin-top: 75px;
  display: grid;
  grid-template-columns: min-content auto min-content;
  gap: auto;
}
.products {
  padding: 20px;
  background: #ffffff;
}
.products h2 {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 15px;
}
.columns {
  display: grid;
  grid-template-columns: 150px 150px 150px;
  gap: 50px;
}
.column h3 {
  font-size: 16px;
  font-weight: 500;
}
.column h3 {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}
.column ul {
  padding: 20px 0;
}
.column ul li {
  text-align: center;
  list-style: none;
  margin: 5px;
  padding: 5px;
  transition: 0.2s;
  cursor: move;
}
.column ul li p {
  font-size: 14px;
  font-weight: 500;
}
.column ul li:hover {
  background-color: #0000000f;
  border-radius: 4px;
  transform: scale(1.1);
}
.column ul li img {
  max-width: 140px;
  max-height: 100px;
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: block;
  margin: 0 auto;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px;
}
.center svg {
  stroke: #767676;
  width: 400px;
}
.center p {
  margin-top: 40px;
  max-width: 360px;
  text-align: center;
}

.set {
  padding: 20px;
  background: #ffffff;
  width: 400px;

  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.set h2 {
  font-size: 30px;
  font-weight: 700;
}
.set h4 {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 15px;
}
.set ul li {
  list-style: none;
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 10px;
  margin: 5px -20px;
  padding: 4px 20px;
  cursor: pointer;
}
.set ul li:hover {
  background-color: #ffcfcf;
}
.set ul h6 {
  font-size: 15px;
  font-weight: 600;
}
.set ul p {
  font-size: 15px;
  font-weight: 400;
}

.set ul li img {
  max-width: 50px;
  max-height: 50px;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.dragIn {
  width: 100%;
  height: 100%;
  padding: 40px;
  border: 1px dotted #383838;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.dragIn:hover {
  background-color: #0000000c;
}
.dragIn img {
  width: 40px;
  aspect-ratio: 1/1;
}
.bottom {
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 20px;
}
.set button {
  padding: 0.8rem 1.2rem;
  border-radius: 2px;
  font-size: 20px;
  width: 100%;

  font-weight: 700;
  transition: 0.1s;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
  background-color: rgb(70, 67, 255);
  color: white;
}
.set button:hover {
  background-color: rgb(55, 52, 247);
}
.set .bottom h3 {
  font-size: 25px;
  font-weight: 700;
  color: #222222;
}

.follower {
  position: fixed;
  top: 0;
  left: -100%;
  padding: 10px;
  background-color: #0000000f;
  border-radius: 8px;
  transform: translate(-50%, -50%);
  pointer-events: none;
}
.follower img {
  max-width: 140px;
  max-height: 140px;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.inactive {
  filter: grayscale(1);
  pointer-events: none;
}

@media only screen and (max-width: 1400px) {
  .creator {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .creator .center svg {
    width: 100%;
  }
}

@media only screen and (max-width: 1000px) {
  .selection {
    grid-template-columns: 1fr;
    gap: 20px;
    margin-top: 40px;
  }
  .title img {
    width: unset;
    height: 200px;
    margin-left: 0;
  }

  .creator {
    grid-template-columns: 1fr;
    gap: 40px;
  }
  .creator .center {
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  div {
    max-width: calc(100vw - 40px);
  }
  .set {
    width: 100%;
  }

  .selection ul li svg {
    right: 10px;
  }

  .creator {
    margin-top: 40px;
  }

  .columns {
    grid-template-columns: 100px 100px;
    gap: 25px;
  }

  .column ul li img {
    max-width: 100px;
    max-height: 70px;
  }

  .set .bottom h3 {
    font-size: 17px;
  }
  .set button {
    padding: 0.7rem;
    font-size: 16px;
  }
}
</style>

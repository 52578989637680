<template>
  <div class="wrapper">
    <div v-if="this.orders?.length" class="orders">
      <div v-for="(order, i) of this.orders" :key="i" class="order" :style="`background-color: ${i % 2 === 0 ? 'rgb(220, 220, 220)' : 'white'}`">
        <h1>
          Identyfikator MyAURA: <span>{{ order._id }}</span>
        </h1>
        <h3>
          Identyfikator P24: <span>{{ order.p24OrderId || "Brak" }}</span
          >, Token p24: <span>{{ order.tokenP24 || "Brak" }}</span>
        </h3>
        <h3>
          Data <span>{{ new Date(order.date).toLocaleString() }}</span>
        </h3>
        <h3>
          Dane Klienta: <span>imię - {{ order.name }}, nazwisko - {{ order.surname }}</span>
        </h3>
        <h3>
          Kontakt: <span>{{ order.email }}, {{ order.number }}</span>
        </h3>
        <h3>
          Rodzaj rachunku: <span>{{ order.receiptType }}</span>
        </h3>
        <ul v-if="order.receiptType === 'faktura'">
          <li>Nazwa firmy: {{ order.companyName }}</li>
          <li>NIP: {{ order.nip }}</li>
        </ul>
        <h3>
          Adres: <span>{{ order.address }}, {{ order.postalCode }}, {{ order.city }}</span>
        </h3>
        <h3>
          Cena: <span>{{ order.price / 100 }}zł, w tym koszt dostawy: {{ order.deliveryData?.fee / 100 || "Brak" }}zł</span>
        </h3>
        <h3 v-if="order.discountCode">
          Kod zniżkowy: <span>{{ order.discountCode }}</span
          >, cena po zniżce: <span>{{ Math.floor(order.price - order?.totalAmountDiscounted) / 100 }}zł</span>
        </h3>
        <h3>
          Typ dostawy: <span>{{ order.typeOfDelivery }}</span>
        </h3>
        <h3>Dane do wysyłki:</h3>
        <ul v-if="order.typeOfDelivery === 'kurier' && order.seperateDelivery">
          <li>Zastępczy adres do wysyłki:</li>
          <li>{{ order?.deliveryData.deliveryRecepient }}</li>
          <li>{{ order?.deliveryData.deliveryAddress }}</li>
          <li>{{ order?.deliveryData.deliveryPostalCode }}</li>
          <li>{{ order?.deliveryData.deliveryCity }}</li>
        </ul>
        <ul v-if="order.typeOfDelivery === 'kurier' && !order.seperateDelivery">
          <li>Wysyłka na powyższy adres</li>
        </ul>
        <ul v-if="order.typeOfDelivery === 'paczkomat'">
          <li>ID inpost: {{ order?.deliveryData?.inPostId }}</li>
        </ul>
        <ul v-if="order.typeOfDelivery === 'odbiór'">
          <li>Numer telefonu klienta: {{ order.number }}</li>
        </ul>
        <h3>
          Status: <span>{{ order.status }}</span>
        </h3>
        <div class="options">
          <h3>Zmiana statusu:</h3>
          <select v-model="order.newStatus">
            <option value="nieopłacone">nieopłacone</option>
            <option value="opłacone">opłacone</option>
            <option value="gotowe do wysyłki">gotowe do wysyłki</option>
            <option value="wysłane">wysłane</option>
            <option value="anulowane">anulowane</option>
          </select>
          <button @click="this.changeStatus(order, i)">Zapisz</button>
        </div>

        <h3>Produkty:</h3>
        <ul>
          <li v-for="product of order.products" :key="product">{{ product.quantity }}x {{ product.name }}</li>
        </ul>
      </div>
    </div>
    <div v-if="!this.orders?.length" class="orders"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      orders: [],
    };
  },
  props: {
    token: String,
  },
  methods: {
    async getOrders() {
      const req = await fetch("/api/admin/orders", {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${this.token}`,
        },
      });
      const res = await req.json();
      this.orders = res.data;
    },
    async changeStatus(order, number) {
      const req = await fetch(`/api/admin/orders/${order._id}`, {
        method: "PUT",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${this.token}`,
        },
        body: JSON.stringify({ status: order.newStatus }),
      });
      const res = await req.json();
      this.$emit("serverMessage", res.message);
      this.orders[number] = res.data;
    },
    filter(status = "anulowane") {
      this.orders = this.orders.filter((el) => el.status === status);
    },
  },
  mounted() {
    this.getOrders();
  },
  created() {
    document.title = `MyAURA`;
  },
};
</script>

<style scoped>
.wrapper {
  min-height: calc(100vh - var(--nav-height-mobile));

  user-select: text;
  -webkit-user-select: text;
}
.order {
  padding: 20px;
}
li {
  list-style: none;
}
span {
  font-weight: 400;
}
.options {
  border-top: 1px solid #9b9b9b;
  border-bottom: 1px solid #9b9b9b;
  margin: 10px 0;
  padding: 10px 0;

  display: flex;
  gap: 10px;
}
.options select {
  padding: 4px;
  font-size: 16px;
}
.options select option {
  font-size: 14px;
}
.options button {
  padding: 4px 10px;
}
</style>

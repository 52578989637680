import { createRouter, createWebHistory } from "vue-router";

import HomeView from "../views/HomeView.vue";
import ProductView from "../views/ProductView.vue";
import PanelView from "../views/PanelView.vue";
import SetCreatorView from "../views/SetCreatorView.vue";
import EditAccountView from "../views/EditAccountView.vue";
import CheckoutView from "../views/CheckoutView.vue";
import SignupView from "../views/SignupView.vue";
import RedirectView from "../views/RedirectView.vue";
import OrderSuccessView from "../views/OrderSuccessView.vue";
import BrowseProductsView from "../views/BrowseProductView.vue";
import AboutView from "../views/AboutView.vue";
import MailUnsubView from "../views/MailUnsubView.vue";
import PrivacyView from "../views/PrivacyView.vue";
import StatuteView from "../views/StatuteView.vue";
import NotFoundView from "../views/NotFoundView.vue";
import AdminOrderView from "../views/AdminOrderPanel.vue";
import AdminProductView from "../views/AdminProductPanel.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/panel",
    name: "Panel",
    component: PanelView,
  },
  {
    path: "/account",
    name: "Account",
    component: EditAccountView,
  },
  {
    path: "/products",
    name: "BrowseProducts",
    component: BrowseProductsView,
  },
  {
    path: "/products/:id",
    name: "Product",
    component: ProductView,
  },
  {
    path: "/checkout",
    name: "Checkout",
    component: CheckoutView,
  },
  {
    path: "/set-creator",
    name: "SetCreator",
    component: SetCreatorView,
  },
  {
    path: "/signup",
    name: "Signup",
    component: SignupView,
  },
  {
    path: "/order-success",
    name: "OrderSuccess",
    component: OrderSuccessView,
  },
  {
    path: "/rdr/:id",
    name: "Redirect",
    component: RedirectView,
  },
  {
    path: "/about",
    name: "About",
    component: AboutView,
  },
  {
    path: "/unsubscribe-from-mail",
    name: "MailUnsubscribe",
    component: MailUnsubView,
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: PrivacyView,
  },
  {
    path: "/statute",
    name: "Statute",
    component: StatuteView,
  },
  {
    path: "/admin/orders",
    name: "AdminOrderPanel",
    component: AdminOrderView,
  },
  {
    path: "/admin/products",
    name: "AdminProductPanel",
    component: AdminProductView,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFoundView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;

<template>
  <div class="error">
    <h1>Nie jesteś zalogowany.</h1>
    <button @click="action">Zaloguj się</button>
  </div>
</template>

<script>
export default {
  methods: {
    action() {
      this.$router.push("/");
      this.$emit("openLoginPrompt");
    },
  },
};
</script>

<style scoped>
.error {
  min-height: calc(100vh - var(--nav-height-mobile));
  padding: 100px 20px;
  text-align: center;
}
.error button {
  padding: 0.8rem 1rem 0.8rem 1.2rem;
  border-radius: 2px;
  font-size: 18px;

  margin: 2rem auto;

  background-color: rgb(70, 67, 255);
  font-weight: 700;
  color: white;
  border: none;
  transition: 0.1s;
  cursor: pointer;
}
.error button:hover {
  background-color: rgb(55, 52, 247);
}
</style>
